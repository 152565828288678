import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { decodeJwt } from "./SecureRequest";
import { setAuthorities } from "redux/features/authenticate/authSlice";
import { useDispatch } from "react-redux";

function PrivateRoute({ children, ...rest }) {
    const token = useSelector((store) => store.authSlice.accessToken);
    const history = useHistory();
    const decodedToken = decodeJwt(token);
    const dateNow = new Date();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        console.log("decodedToken-------->>>>>>>>>>>>",decodedToken);
        console.log("Token expiry------->>>>>>>>>>>>",decodedToken.exp);
        console.log("Current time------->>>>>>>>>>>>",dateNow.getTime());
        const valid = token && (decodedToken.exp * 1000 > dateNow.getTime());
        console.log("token state: ",valid)
        if(!valid){
            history.push("/auth")
        }
        dispatch(setAuthorities(decodedToken))
    },[token]);
    
    return (
      <Route
        {...rest}
        render={() => {
          return (token && (decodedToken.exp * 1000 > dateNow.getTime())) ? (
            children
          ) : (
            <Redirect to="/auth/login" />
          );
        }}
      />
    );
  }

  export default PrivateRoute;