import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { profileApi } from "./features/services/profile.serivce";
import { processApi } from "./features/services/process";
import { subProcessApi } from "./features/services/subProcess";
import { dashboardApi } from "./features/services/dashboard.service"
import { configApi } from "./features/services/config.serivce";
import { searchApi } from "./features/services/search.service";
import { contactApi } from "./features/services/contact.service";
import { registerUserApi } from "./features/authenticate/registerUser.service";
import authSlice from './features/authenticate/authSlice';
import { eventApi } from "./features/services/event.service";

export const store = configureStore({
  reducer: {
    [profileApi.reducerPath]: profileApi.reducer,
    [processApi.reducerPath]: processApi.reducer,
    [subProcessApi.reducerPath]: subProcessApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [registerUserApi.reducerPath]: registerUserApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    authSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApi.middleware,
      processApi.middleware,
      subProcessApi.middleware,
      dashboardApi.middleware,
      configApi.middleware,
      searchApi.middleware,
      contactApi.middleware,
      registerUserApi.middleware,
      eventApi.middleware
    )
});

setupListeners(store.dispatch);
