import React, { useEffect, useState } from "react";
import { useSearchProfileQuery } from "redux/features/services/profile.serivce";

export default function ProfileFinder({id, targetAttribute, resolver, fallback}){

    const {data, isLoading, isSuccess, isError} = useSearchProfileQuery({id});

    const resolve = () =>{
        if(resolver){
            const value = resolver(data);
            return value;
        }
        return (targetAttribute) ? data[targetAttribute] : id;
    }

    return (
        <>
            {(isSuccess && data) ? resolve() : fallback}
        </>
    )
}