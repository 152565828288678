import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BPM_API }),
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getProfiles: builder.query({
      // query: () => `/api/v1/bpm/profile/paged?page=0&size=10&sort=lastModifiedDate,desc`,
      query: ({page, size, sort}) => `/api/v1/bpm/profile/paged?page=${page}&size=${size}&sort=${sort}`
    }),
    searchProfile: builder.query({
      query: ({id}) => `/api/v1/bpm/profile/search/${id}`
    }),
    createProfile: builder.mutation({
      query: (profile) => ({
        url: '/api/v1/bpm/profile',
        method: 'POST',
        body: profile,
      }),
    }),
    updateProfile: builder.mutation({
      query: ({ profileId, body }) => ({
        url: `/api/v1/bpm/profile/${profileId}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetProfilesQuery,
  useSearchProfileQuery,
  useLazySearchProfileQuery,
  useCreateProfileMutation,
  useUpdateProfileMutation,
} = profileApi;
