import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DataGrid from "components/Cards/DataGrid";
import { useGetSubProcessV2Query } from "redux/features/services/subProcess";
import { MasterFilter } from "components/QueryBuilder/MasterFilter";
import { DialogContent, DialogActions, DialogTitle } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./Masterdata.css";

export default function MasterData() {
  const [query, setQuery] = useState("");
  const { data: subProcessMaster, isLoading: isSubProcessLoading } =
    useGetSubProcessV2Query({ page: 0, size: 5, jsonQuery: query });
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const resetFilter = () => {
    setQuery();
  };

  const handleQuery = (queryJson) => {
    handleClose();
    setQuery(queryJson);
  };

  return (
    <>
      <div className="flex flex-wrap mt-0">
        <div className="w-full mb-12 px-4">
          {isSubProcessLoading ? (
            <p>Loading...</p>
          ) : (
            <DataGrid
              openFilter={handleOpen}
              resetFilter={resetFilter}
              subprocess={subProcessMaster}
            />
          )}
        </div>
      </div>
      <Modal style={{display:'flex',alignItems:'center',justifyContent:'center', width:'100%'}} open={open} onClose={handleClose}>
        <div>
          <DialogTitle id="id">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>Query Builder</Box>
              <Box>
                <IconButton onClick={(e) => handleClose(e, "BUTTON")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <MasterFilter handleQuery={handleQuery} />
          </DialogContent>
          <DialogActions></DialogActions>
        </div>
      </Modal>
    </>
  );
}
