import { setAccessToken, setRefreshToken, logout } from "redux/features/authenticate/authSlice";
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {jwtDecode} from 'jwt-decode';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BPM_API,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().authSlice.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  }
})

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.originalStatus === 403) {
    console.log('sending refresh token')
    // send refresh token to get new access token
    const response = await baseQuery('/v1/auth/refresh-token', api, extraOptions)
    console.log(response)
    if (response?.data) {
      // const user = api.getState().componentSlice.user
      // store the new token
      api.dispatch(setAccessToken(response.data.access_token));
      api.dispatch(setRefreshToken(response.data.refresh_token));
      // dispatch(setUser(email));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout())
    }
  }

  return result
}

export const decodeJwt = (token) => {
  try {
      return jwtDecode(token);
  } catch (e) {
      console.log("error decoding token");
      return "INVALID";
  }
}