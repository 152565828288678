import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Autocomplete from '@mui/material/Autocomplete';
import { useCreateContactMutation, useGetContactsQuery } from "redux/features/services/contact.service";
// import { useGetConfigQuery } from "redux/features/services/config.serivce";

export default function ContactForm({ onClose, onContactAdd }) {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    // const [type, setType] = useState("");
    // const [profileCode, setProfileCode] = useState("");

    const [ createContact, {isLoading} ] = useCreateContactMutation();
    // const { data: config } = useGetConfigQuery("PROFILE");
    const {refetch}= useGetContactsQuery()
    
    // // Validation states
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [typeError, setTypeError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Initialize Snackbar visibility state
  
    const validateForm = () => {
      let isValid = true;
  
      if (firstName.trim() === "") {
        setFirstNameError("First Name is required");
        isValid = false;
      } else {
        setFirstNameError("");
      }
  
      if (lastName.trim() === "") {
        setLastNameError("Last Name is required");
        isValid = false;
      } else {
        setLastNameError("");
      }
  
      if (email.trim() === "") {
        setEmailError("Email is not now required");
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Invalid email format");
        isValid = false;
      } else {
        setEmailError("");
      }
  
      if (mobile.trim() === "") {
        setMobileError("Mobile is required");
        isValid = false;
      } else {
        setMobileError("");
      }
  
      const mobileWithoutSpaces = mobile.replace(/\s/g, '');
      if (
        !/^\d{10}$/.test(mobileWithoutSpaces) &&
        !/^\+\d{2}-\d{10}$/.test(mobileWithoutSpaces)
      ) {
        setMobileError("Mobile should be 10 digits or in the format +91-10digits");
        isValid = false;
      } else {
        setMobileError("");
      }
  
      // if (type.trim() === "") {
      //   setTypeError("Profile Type is required");
      //   isValid = false;
      // } else {
      //   setTypeError("");
      // }
  
      return isValid;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!validateForm()) {
        return;
      }
  
      try {
        await createContact({
          firstName,
          lastName,
          email,
          mobile,
          // type,
          // profileCode,
        });
  
        onContactAdd({
          id: Math.random(),
          firstName,
          lastName,
          email,
          mobile,
          // type,
          // profileCode,
        });
  
        refetch()
        setSuccessMessage("Profile created successfully!");
  
        setTimeout(() => {
          onClose();
        }, 600);
      } catch (error) {
        console.error("Failed to create profile", error);
      }
    };

    return (
        <>
        <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          zIndex: 999,
        }}
      >
        <Typography variant="h4" align="center">
          Add Contact
        </Typography>
        <form
        onSubmit={handleSubmit}
        >
          <Stack
            component="form"
            sx={{
              width: "100%",
            }}
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <TextField
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="First Name"
              variant="outlined"
              error={!!firstNameError}
              helperText={firstNameError}
            />
            <TextField
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Last Name"
              variant="outlined"
              error={!!lastNameError}
              helperText={lastNameError}
            />
            <TextField
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              variant="outlined"
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              label="Mobile"
              variant="outlined"
              error={!!mobileError}
              helperText={mobileError}
            />
            {/* <Autocomplete
                  options={config?.store?.TYPE?.map(t=> t)}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setTypeError('Profile Type is required');
                    } else {
                      setTypeError('');
                    }
                    setType(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={type}
                      label="Profile Type"
                      variant="outlined"
                      error={!!typeError}
                      helperText={typeError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                /> */}

            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              onClick={handleSubmit}
            >
              {isLoading ? "Adding..." : "Add"}
            </Button>
          </Stack>
        </form>
      </Box>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
        </>
  )
}
