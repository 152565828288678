import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BPM_API }),
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: (storeName) => `/api/v1/bpm/config/${storeName}`,
    })
  })
});

export const {
  useGetConfigQuery
} = configApi;
