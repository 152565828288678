import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ProcessForm from "views/admin/AddProcessForm";
import { DataGrid } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PrintIcon from "@mui/icons-material/Print";
import { saveAs } from "file-saver";
import "./DataGrid.css";

const columns = [
  {
    field: "profileCode",
    headerName: "PROFILE",
    flex: 1.2,
    headerClassName: "custom-header",
  },
  {
    field: "attendeeCode",
    headerName: "ASSIGNED",
    flex: 1,
    headerClassName: "custom-header",
  },
  {
    field: "client",
    headerName: "CLIENT",
    flex: 1,
    headerClassName: "custom-header",
  },
  {
    field: "interviewPanel",
    headerName: "PANEL",
    flex: 1,
    headerClassName: "custom-header",
  },
  {
    field: "interviewMode",
    headerName: "VENUE",
    flex: 1,
    headerClassName: "custom-header",
  },
  {
    field: "source",
    headerName: "SOURCE",
    flex: 0.8,
    headerClassName: "custom-header",
  },
  {
    field: "rate",
    headerName: "RATE",
    type: "number",
    flex: 0.5,
    align: "left",
    headerAlign: "left",
    headerClassName: "custom-header",
  },
  {
    field: "interviewDate",
    headerName: "DATE",
    flex: 1,
    headerClassName: "custom-header",
  },
  {
    field: "time",
    headerName: "TIME",
    flex: 0.6,
    headerClassName: "custom-header",
  },
  {
    field: "status",
    headerName: "STATUS",
    flex: 1,
    headerClassName: "custom-header",
  },
];

export default function DataGridComponent({
  color,
  subprocess,
  openFilter,
  resetFilter,
}) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelect = (selection) => {
    setSelected(selection.selectionModel);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const handleDownload = () => {
    function convertToCSV(data) {
      const header = columns.map((column) => column.field);
      const rows = data.map((item) => header.map((field) => item[field]));
      const csvContent = [
        header.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "table_data.csv");
    }

    convertToCSV(subprocess);
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Master Data
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <Button
                variant="contained"
                style={{ margin: "3px" }}
                onClick={openFilter}
                startIcon={<FilterAltIcon />}
              >
                Filter
              </Button>
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={resetFilter}
                startIcon={<FilterAltOffIcon />}
              >
                Reset
              </Button>
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={() => window.print()}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
        <div style={{ height: 730, width: "100%" }}>
          <DataGrid
            rows={subprocess ? subprocess : []}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={handleSelect}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            hideFooter={true}
          />
        </div>
      </div>
    </>
  );
}
