import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registerUserApi = createApi({
  reducerPath: "registerUserApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BPM_API }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (user) => ({
        url: "/v1/auth/register",
        method: "POST",
        body: user,
      }),
    }),
    authenticateUser: builder.mutation({
      query: (user) => ({
        url: "/v1/auth/authenticate",
        method: "POST",
        body: user,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (payload) => ({
        url: "/v1/auth/reset-password",
        method: "POST",
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: `/v1/auth/reset-password/${payload?.token}`,
        method: "POST",
        body: {newPassword: payload?.newPassword,confirmPassword: payload?.confirmPassword},
      }),
    }),
  }),
});

export const { useRegisterUserMutation, useAuthenticateUserMutation, useForgetPasswordMutation, useResetPasswordMutation } = registerUserApi;
