import React, { useState } from "react";
import { createPopper } from "@popperjs/core";
import DateRange from "components/daterange/DateRange.js";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { Button } from "@mui/material";

const DateRangeDropdown = (props) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const { handleDateRangeChange } = props;
  const [selectedRange, setSelectedRange] = useState(null);
  const [defaultRange, setDefaultRange] = useState(()=>{
    const d = new Date();
    const startDate = d.getFullYear()+"-01-01";
    const endDate = d.getFullYear()+"-12-31";
    return {startDate,endDate};
  });
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleRangeChange = (change) => {
    setSelectedRange(change[0]); // Store the selected range in state
    handleDateRangeChange(change);
  };

  const handleApply = () => {
    // You can add custom logic here if needed before closing
    closeDropdownPopover();
  };

  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          {selectedRange ? (
            <span className="text-sm mr-2">
              <span
                className="bg-white text-black px-2 py-1 rounded"
                style={{ display: "inline-block" }}
              >
                {selectedRange.startDate.toDateString()} -{" "}
                {selectedRange.endDate.toDateString()}
              </span>
            </span>
          ) : (
            <span className="text-sm mr-2">
              <span
                className="bg-white text-black px-2 py-1 rounded"
                style={{ display: "inline-block" }}
              >
                {defaultRange.startDate} -{" "}
                {defaultRange.endDate}
              </span>
            </span>
          )}
          <span className="w-12 h-12 text-white bg-white inline-flex items-center justify-center rounded-full">
            <CalendarMonthRoundedIcon style={{ color: "black" }} />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-1000 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
      <div className="main_range_dv"  style={{
    position: "relative"
  }}>
      <DateRange handleRangeChange={handleRangeChange} />
      <Button
  onClick={handleApply}
  variant="contained"
  style={{
    position: "absolute",
    left: "68px",
    bottom: "0px",
  }}
>
  Apply
</Button>
      </div>

      </div>
    </>
  );
};

export default DateRangeDropdown;
