import React from 'react';
import "./AccessDenied.css";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { BlockOutlined } from '@mui/icons-material';

export default function AccessDenied(){

    return (
        <>
      <div className="container mx-auto px-4 h-full ">
        <div className="flex content-center items-center justify-center h-full ">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 main_shadow_dv ">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold text_b">
                    Access Denied!
                  </h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 login_form_dv">
                <BlockOutlined style={{ color: "red", width: "150px", height: "150px"}}/>
                <div className="left_content-dv">
                    <h1>You do not have permission to access the requested resource.</h1><br />
                    <h3 style={{ color: "red"}}><b>Please contact your administrator.</b></h3>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
    )
}