 import React, { useState } from "react";
import '../../layouts/Reset_Password.css'
import { useResetPasswordMutation } from "redux/features/authenticate/registerUser.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ResetPassword() {

  const [newPassword, setNewPassword] = useState("")
  const [newPasswordErr, setNewPasswordErr] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("")
  const history = useHistory();
  const [resetUserPassword] = useResetPasswordMutation();

  const validateForm = () => {
    let isValid = true;

    if (newPassword.trim() === "") {
      setNewPasswordErr("password is required");
      isValid = false;
    } else {
      setNewPasswordErr("");
    }
    if (confirmPassword.trim() === "") {
      setConfirmPasswordErr("confirm password is required");
      isValid = false;
    } else if (newPassword !== confirmPassword) {
      isValid = false;
      setConfirmPasswordErr("Password does not match.")
    } else {
      setConfirmPasswordErr("");
    }

    return isValid;
  };

  const handleRedirect = () => {
    console.log("password reset successfull, redirecting to login page...")
    history.push("/auth");
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const sp = new URLSearchParams(window.location.search)
    if (!validateForm()) {
      return;
    }

    try {
      await resetUserPassword({ newPassword: window.btoa(newPassword), confirmPassword: window.btoa(confirmPassword), token: sp.get('token') });
      handleRedirect();
    } catch (error) {
      console.error("Reset password operation failed", error);
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full ">
        <div className="flex content-center items-center justify-center h-full ">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 main_shadow_dv ">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold text_b">
                    Set a new password
                  </h6>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 login_form_dv">

                <form>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 E_label"
                      htmlFor="grid-password"
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {newPasswordErr && <span className="text-red-500 text-xs">{newPasswordErr}</span>}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 E_label"
                      htmlFor="grid-password"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {confirmPasswordErr && <span className="text-red-500 text-xs">{confirmPasswordErr}</span>}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 shadow_lg_dv"
                      type="button"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
