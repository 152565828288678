import CardStats from "components/Cards/CardStats.js";
import { useGetProcessGroupedByStatusQuery } from '../../redux/features/services/dashboard.service'
import { useState } from "react";
import { useEffect  } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SearchFilter from "./SearchFilter";


export default function HeaderStats() {

  const { data: processGroupedByStatus = [], isLoading } = useGetProcessGroupedByStatusQuery();
  const [staticals, setStaticals] = useState([]);
  const location = useLocation();

  const buildStatics=()=>{
    let statsObj = {};
    processGroupedByStatus && processGroupedByStatus.forEach(element => {
      if(element.status==="TOTAL"){
        statsObj["TOTAL"] = element.count;
      }
      if(element.status==="TECHNICAL"){
        statsObj["TECHNICAL"] = element.count;
      }
      if(element.status==="ONBOARDED"){
        statsObj["ONBOARDED"] = element.count;
      }
      if(element.status==="DECLINED"){
        statsObj["DECLINED"] = element.count;
      }
      if(element.status==="REJECTED"){
        statsObj["REJECTED"] = element.count;
      }
    });
    const negativeC = statsObj["ONBOARDED"] || 0+statsObj["DECLINED"] || 0 +statsObj["REJECTED"] || 0;
    statsObj["IN_PROCESS"] = statsObj["TOTAL"]-negativeC;
    setStaticals(statsObj);
  };

  useEffect(() =>{
    buildStatics()
  },[isLoading]);

  return (
    <>
      {/* Header */}
      {location.pathname !=="/admin/dashboard" ? (
        <div className="relative bg-lightBlue-600 md:pt-32 pb-20 pt-12">

        </div>
        
      ) :
      ( <div className="relative bg-lightBlue-600 md:pt-8 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div><SearchFilter/></div>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Projects Win"
                  statTitle={staticals.ONBOARDED || 0}
                  statArrow="down"
                  statPercent="1.10"
                  statPercentColor="text-orange-500"
                  statDescripiron="Since yesterday"
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Technical Scheduled"
                  statTitle={staticals.TECHNICAL || 0}
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="In-Process"
                  statTitle={staticals.IN_PROCESS || 0}
                  statArrow="up"
                  statPercent="12"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Total Leads"
                  statTitle={staticals.TOTAL || 0}
                  statArrow="up"
                  statPercent="3.48"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
            </div>
          </div>
        </div>
        </div>)}
    </>
  );
}
