import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";

export default function DateRange(props) {
  const { handleRangeChange } = props;

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleChange = (selection) => {
    if (handleRangeChange) {
      handleRangeChange(selection);
    }
    setState(selection);
  };

  return (
    <>
      {console.log("New Date Range: ", state)}
      <DateRangePicker
        onChange={(item) => handleChange([item.selection])}
        dateDisplayFormat="yyyy-MM-dd"
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
        preventSnapRefocus={true}
        calendarFocus="backwards"
      />
    </>
  );
}
