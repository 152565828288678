import React from "react";
import Chart from "chart.js";
import { useGetInterviewTrendsQuery } from '../../redux/features/services/dashboard.service'

export default function CardBarChart() {

  const { data: interviewTrends = [], isLoading } = useGetInterviewTrendsQuery();

  const buildChart = () =>{
    const _labels = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const _created = [];
    const _technical = [];
    if(!isLoading){
      
      for(let key of _labels){
        let createdValue = interviewTrends["CREATED"] ? interviewTrends["CREATED"][key] : 0;
        _created.push(createdValue);
        let techniclValue = interviewTrends["TECHNICAL"] ? interviewTrends["TECHNICAL"][key] : 0;
        _technical.push(techniclValue);
      }
    }   
    let config = {
      type: "bar",
      data: {
        labels: _labels,
        datasets: [
          {
            label: "Total Created",
            backgroundColor: "#ed64a6",
            borderColor: "#ed64a6",
            data: _created,
            fill: false,
            barThickness: 8,
          },
          {
            label: "Technical Scheduled",
            fill: false,
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            data: _technical,
            barThickness: 8,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx = document.getElementById("bar-chart").getContext("2d");
    window.myBar = new Chart(ctx, config);
  }

  React.useEffect(() => {
    buildChart();
  }, [isLoading]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Performance
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Total Process
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="bar-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
