import CardContact from 'components/Cards/CardContact';
import React from 'react'
import { useGetContactsQuery } from 'redux/features/services/contact.service';

export default function Contact() {

    const {data: contactData, isLoading, isSuccess, isError}= useGetContactsQuery();

    if (isLoading) {
        return <div>Loading...</div>;
      }
    
      if (isError) {
        return <div style={{color:"red"}}>Error in fetching data</div>;
      }
    
      if (isSuccess) {      
        // console.log("contact data we are getting from api",contactData)  
        return (
            <div>
            <div className="flex flex-wrap mt-0">
                <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-0">
                {isLoading ? (
                    <p>Loading...</p>
                    ) : (
                        <CardContact contactData= {contactData?.content}/>
                        )}
                </div>
            </div>
            </div>
        )
    }
}
