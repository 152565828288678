import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BPM_API }),
  endpoints: (builder) => ({
    getProcessGroupedByStatus: builder.query({
      query: () => `/api/v1/bpm/process/statusCounts`,
    }),
    getInterviewTrends: builder.query({
      query: () => `/api/v1/bpm/process/technical-interview-trends`,
    })
  }),
});

export const {
  useGetProcessGroupedByStatusQuery,
  useGetInterviewTrendsQuery
} = dashboardApi;