import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({ baseUrl : process.env.REACT_APP_BPM_API }),
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: () => `/api/v1/bpm/contact?Page=0&size=10`,
    }),
    createContact: builder.mutation({
        query: (contact) => ({
          url: '/api/v1/bpm/contact',
          method: 'POST',
          body: contact,
        }),
      }),
    deleteContact: builder.mutation({
        query: (id) => {
          console.log("id we are getting in api to delete",id)
          return {
            url: `/api/v1/bpm/contact/${id}`,
            method: 'DELETE',
          }
        },
      }),
  }),
});

export const {
  useGetContactsQuery,
  useCreateContactMutation,
  useDeleteContactMutation
} = contactApi;
