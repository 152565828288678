import React from "react";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useGetSearchedQuery } from "redux/features/services/search.service";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import TerminalIcon from "@mui/icons-material/Terminal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./SearchResult.css";
import { Button } from "@mui/material";
import ProcessForm from "./AddProcessForm";
import AddSubProcessForm from "./AddSubProcessForm";
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom/cjs/react-router-dom";
import Profile from "./Profile";
import Chip from '@mui/material/Chip';
import ProfileFinder from "components/utils/ProfileFinder";

export default function SearchResult({
  filterValue,
  flag,
  processData: initialProcessData,
}) {
  const {
    data: usersData,
    isLoading,
    isSuccess,
    isError,
  } = useGetSearchedQuery(filterValue);

  const [openProfileModal, setOpenProfileModal] = useState(false)
  const [profileData, setProfileData] = useState(null)

  const handleProfileModalOpen = () => {
    setOpenProfileModal(true);
  };

  const handleProfileModaleClose = () => {
    setOpenProfileModal(false);
  };

  const [openProcessModalForm, setopenProcessModalForm] = useState(false);
  const [processData, setProcessData] = useState(initialProcessData);

  const handleProcessModalOpen = () => {
    setopenProcessModalForm(true);
  };

  const handleProcessModaleClose = () => {
    setopenProcessModalForm(false);
  };
  const handleAddProcess = (newProcess) => {
    handleProcessModaleClose();
  };

  const handleProcessFormClose = () => {
    handleProcessModaleClose();
  };

  const [openSubProcessModalForm, setOpenSubProcessModalForm] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  const handleSubProcessModalOpen = (user) => {
    setSelectedProcessId(user.id)
    setOpenSubProcessModalForm(true);
  };

  const handleSubProcessModalClose = () => {
    setOpenSubProcessModalForm(false);
    setSelectedProcessId(null)
  };

  const handleAddSubProcess = () => {
    handleSubProcessModalClose();
  };

  const handleSubProcessFormClose = () => {
    handleSubProcessModalClose();
  };

  const handleLinkProfile=(userCategory)=>{
    console.log(userCategory)
    localStorage.setItem("userCategory", userCategory);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div style={{ color: "red" }}>Error in fetching data</div>;
  }

  if (isSuccess) {
    {
      console.log("Searched data", usersData);
    }

    const userRows = usersData.map((userGroup, index) => {
      const userCategory = Object.keys(userGroup)[0];
      const userData = userGroup[userCategory];
      if (userCategory === "CONTACT") {
        return (
          <>
            <tr key={index} className="common_top_cnt">
              <td>{userCategory}</td>
            </tr>
            <tr>
              <td>
                <table>
                  <thead className="full_width_dv">
                    <tr>
                      <th>
                        <PersonOutlineIcon />
                        First Name
                      </th>
                      <th>
                        <PersonOutlineIcon />
                        Last Name
                      </th>
                      <th>
                        <EmailIcon />
                        Email
                      </th>
                      <th>
                        Organization
                      </th>
                      <th>
                        <PhoneAndroidIcon />
                        Mobile
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="full_width_dv ctd">
                    {userData.map((user, userIndex) => (
                      <tr key={user.id}>
                        <td>
                          {user.firstName}
                        </td>
                        <td>
                          {user.lastName}
                        </td>
                        <td>
                          {user.email}
                        </td>
                        <td>
                          {user.organization}
                        </td>
                        <td>
                          {user.mobile}
                        </td>
                        <td>
                          <Link to={`./profile/${user.email}`} onClick={()=>handleLinkProfile(userCategory)}>
                            <LaunchIcon/>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        );
      }

      if (userCategory === "PROFILE") {
        return (
          <>
            <tr key={index} className="common_top_cnt">
              <td>{userCategory}</td>
            </tr>
            <tr>
              <td>
                <table>
                  <thead className="full_width_dv">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Cell</th>
                      <th>Code</th>
                    </tr>
                  </thead>
                  <tbody className="full_width_dv ctd">
                    {userData.map((user, userIndex) => (
                      <tr key={user.id}>
                        <td>
                          {user.firstName + " " + user.lastName}
                        </td>
                        <td>
                          {user.email}
                        </td>
                        <td>
                          {user.mobile}
                        </td>
                        <td>
                          {user.code}
                        </td>
                        <td>
                        <td>
                          <Link to={`./profile/${user.email}`} onClick={()=>handleLinkProfile(userCategory)}>
                            <LaunchIcon/>
                          </Link>
                        </td>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        );
      }

      if (userCategory === "PROCESS") {
        return (
          <>
            <tr key={index} className="common_top_cnt">
              <td>{userCategory}</td>
            </tr>
            <tr>
              <td>
                <table>
                  <thead className="full_width_dv">
                    <tr>
                      <th>
                        <PersonAddAltIcon />
                        Client
                      </th>
                      <th>
                        <CalendarMonthIcon />
                        Creation Date
                      </th>
                      <th>
                        {" "}
                        <AttachEmailIcon />
                        Client Email
                      </th>
                      <th>
                        <TerminalIcon />
                        Profile
                      </th>
                      <th>
                        <TerminalIcon />
                        Consultant
                      </th>
                      <th>
                        <CurrencyExchangeIcon />
                        Rate
                      </th>
                      <th>
                        Tags
                      </th>
                      <th>
                        <AutorenewIcon />
                        Status
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="full_width_dv ctd">
                    {userData.map((user, userIndex) => (
                      <tr key={user.id}>
                        <td>
                          {user.client}
                        </td>
                        {/* <td><TerminalIcon/>{user.profileCode}</td> */}
                        <td>
                          {user.creationDate}
                        </td>
                        <td>
                          {user.clientEmail}
                        </td>
                        <td>
                          <ProfileFinder id={user.profileCode} resolver={(data)=>data['firstName']+" "+data['lastName']} />
                        </td>
                        <td>
                          {user.consultant?.split('@')[0]}
                        </td>
                        <td>
                          {user.rate}
                        </td>
                        <td>
                          {user.tags ? user.tags.split(',').map(t=>(<Chip label={t.trim()} color="primary" />)) : ""}
                        </td>
                        <td>
                          {user.status}
                        </td>
                        <td>
                          <Link to={`./process/${user.id}`}>
                            <LaunchIcon/>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        );
      }

      if (userCategory === "SUB-PROCESS") {
        return (
          <>
            <tr key={index} className="common_top_cnt">
              <td>{userCategory}</td>
            </tr>
            <tr key={index}>
              {/* <td>{userCategory}</td> */}
              <td>
                <table>
                  <thead className="full_width_dv">
                    <tr>
                      <th>
                        <PersonAddAltIcon />
                        Client
                      </th>
                      <th>
                        <TerminalIcon />
                        Profile
                      </th>
                      <th>
                        <TerminalIcon />
                        Consultand
                      </th>
                      <th>
                        <SubtitlesIcon />
                        Interview Pannel
                      </th>
                      <th>
                        <CalendarMonthIcon />
                        Interview Date
                      </th>
                      <th>
                        <AccessTimeIcon />
                        Time
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="full_width_dv ctd">
                    {userData.map((user, userIndex) => (
                      <tr key={user.id}>
                        <td>
                          {user.client}
                        </td>
                        <td>
                          <ProfileFinder id={user.profileCode} resolver={(data)=>data['firstName']+" "+data['lastName']} />
                        </td>
                        <td>
                          <ProfileFinder id={user.attendeeCode} resolver={(data)=>data['firstName']+" "+data['lastName']} />
                        </td>
                        <td>
                          {user.interviewPanel}
                        </td>
                        <td>
                          {user.interviewDate}
                        </td>
                        <td>
                          {user.time}
                        </td>
                        <td>
                          <Link to={`./process/${user.processId}`}>
                            <LaunchIcon/>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        );
      }
    });

    return flag ? (
      <div className="Search_data_dv">
        {/* <h2>Search Result</h2> */}
        <div className="Search_data_dv_inner">
          <table>
            <thead>
              <tr>{/* <th>Category</th> */}</tr>
            </thead>
            <tbody>{userRows}</tbody>
          </table>
        </div>
      </div>
    ) : (
      " "
    );
  }
  return null;}
