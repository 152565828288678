import React, { useState } from "react";
import '../../layouts/Login_page.css'
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { useAuthenticateUserMutation } from "redux/features/authenticate/registerUser.service";
import { setAccessToken, setRefreshToken, setUser } from "redux/features/authenticate/authSlice";
import Admin from "layouts/Admin";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmail } from "components/utils/Constant";
import Button from "@mui/material/Button";


export default function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [passwordErr, setPasswordErr] = useState("")
  const [authenError, setAuthenError] = useState("")
  const history = useHistory();
  const dispatch = useDispatch();
  const [authenticateUser] = useAuthenticateUserMutation();
  const [incorrectPasswordAttempts, setIncorrectPasswordAttempts] = useState(0);
  const [incorrectPasswordErr, setIncorrectPasswordErr] = useState("");
  const [failedAttempts, setFailedAttempts] = useState(0);

  const onChange = () => {};

  const validateForm = () => {
    let isValid = true;

    if (email.trim() === "") {
      setEmailErr("Email is required");
      isValid = false;
    } else if (!isValidEmail.test(email.trim())) {
      setEmailErr("Invalid email format");
      isValid = false;
    } else {
      setEmailErr("");
    }

    if (password.trim() === "") {
      setPasswordErr("password is required");
      isValid = false;
    } else {
      setPasswordErr("");
    }
    return isValid;
  };

  const handleRedirect = () => {
    console.log("Login successfull, redirecting...")
    history.push("/admin");
  }
  
  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await authenticateUser({ email, password })
            .unwrap()
            .then((payload) => {
              console.log('Authentication Successful.', payload);
              setIncorrectPasswordAttempts(0);
              dispatch(setAccessToken(payload?.access_token));
              dispatch(setRefreshToken(payload?.refresh_token));
              dispatch(setUser(email));
              setAuthenError("");
              handleRedirect();
            })
            .catch((err) => {
              console.error('Error Authenticating', err)
              if(err?.status==='FETCH_ERROR'){
                setAuthenError("Uh-oh! Our servers seem to be taking a coffee break ☕. Don't worry, we're on it!");
                return;
              }else {
                setAuthenError(err?.data?.message);
                setFailedAttempts(failedAttempts + 1);
                return;
              }
            });
      
      // if(response?.status === 200) {
      //   setIncorrectPasswordAttempts(0);
      //   dispatch(setAccessToken(response.data.access_token));
      //   dispatch(setRefreshToken(response.data.refresh_token));
      //   dispatch(setUser(email));
      //   setAuthenError("");
      //   handleRedirect();
      // }else {
      //   console.error("Authentication failed, Error: ", response?.data?.message);
      //   setFailedAttempts(failedAttempts + 1);
      //   setAuthenError(response?.data?.message);
      // }
      
    } catch (error) {
      console.error("Authentication failed", error);
      setFailedAttempts(failedAttempts + 1);
      // setAuthenError(err);
    }
  };


  return (
    <>
      <div className="container mx-auto px-4 h-full ">
        <div className="flex content-center items-center justify-center h-full ">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 main_shadow_dv ">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold text_b">

                    Sign in
                  </h6>
                </div>
                {/* <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg").default}
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div> */}
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 login_form_dv">
                {/* <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div> */}
                <div className="left_content-dv">
                  <h2>Blue Think Project </h2>
                  <h2 className="uniq_c">Managment.</h2>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 E_label"
                      htmlFor="grid-password "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailErr && <span className="text-red-500 text-xs">{emailErr}</span>}
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 E_label"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordErr && <span className="text-red-500 text-xs">{passwordErr}</span>}
                  </div>

                  <div className="text-red-500 text-xs">{incorrectPasswordErr}</div>

                  <div className="text-center mt-6">
                    {failedAttempts >= 3 && (
                      <ReCAPTCHA
                      sitekey= "6LduemMpAAAAALNfr6IEdSXgBqcUbHyK-UpJ8Srs"
                      onChange={onChange}
                    />
                    )}
                    <div>
                      {authenError?.trim()!=='' && <span style={{fontWeight: "bold"}}className="text-red-500 text-xs">{authenError}</span>}
                    </div>
                    <Button 
                      disabled={(email.trim() == "" || password.trim() == "")} 
                      onClick={handleSignIn}
                      variant="contained"
                    >
                      Sign In
                    </Button>
                    {/* <button
                      className="bg-blueGray-8 00 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 shadow_lg_dv"
                      type="button"
                      onClick={handleSignIn}
                    >
                      Sign In
                    </button> */}
                  </div>
                  <div className="flex flex-wrap mt-3 relative">
                    <div className="w-1/2">
                      <div className="w-1/2 text-right create_content">
                        <Link to="/auth/forgetPassword" className="text-blueGray-200">
                          <small>Forget Password</small>
                        </Link>
                      </div>

                    </div>
                    <div className="w-1/2 text-right create_content">
                      <Link to="/auth/register" className="text-blueGray-200">
                        <small>Create new account</small>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}