import React, { useState } from "react";
import ProfileForm from "views/admin/AddProfileForm";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import "./CardProfiles.css";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { saveAs } from "file-saver";
import LaunchIcon from '@mui/icons-material/Launch';

export default function CardProfiles({ profileData: initialProfileData }) {
  const [openProfileModalForm, setOpenProfileModalForm] = useState(false);
  const [profileData, setProfileData] = useState(initialProfileData);
  const [editableRow, setEditableRow] = useState(null);
  const [editedProfileData, setEditedProfileData] = useState({});
  const [selected, setSelected] = useState([]);

  const handleProfileModalOpen = () => {
    setOpenProfileModalForm(true);
  };

  const handleProfileModalClose = () => {
    setOpenProfileModalForm(false);
  };

  const handleProfileFormClose = () => {
    handleProfileModalClose();
  };

  const handleProfileAdd = (newProfile) => {
    const updatedProfileData = [...profileData, newProfile];
    setProfileData(updatedProfileData);
  };

  const handleEditClick = (index) => {
    setEditableRow(index);
    setEditedProfileData({ ...profileData[index] });
  };

  const handleSaveEdit = (index) => {
    const updatedProfileData = [...profileData];
    updatedProfileData[index] = editedProfileData;
    setProfileData(updatedProfileData);
    setEditableRow(null);
  };

  const handleSelect = (selection) => {
    setSelected(selection.selectionModel);
  };

  const handleDownload = () => {
    function convertToCSV(data) {
      const header = columns.map((column) => column.field);
      const rows = data.map((item) => header.map((field) => item[field]));
      const csvContent = [
        header.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "table_data.csv");
    }

    convertToCSV(initialProfileData);
  };

  const columns = [
    {
      field: "code",
      headerName: "Profile Code",
      flex: 1,
      headerClassName: "custom-header",
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: (params) => {
        if (params.row.id === editableRow) {
          return (
            <Button
              onClick={() => handleSaveEdit(params.rowIndex)}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          );
        } else {
          return (
            <>
              <EditIcon
                style={{ cursor: "pointer", margin: "10px" }}
                onClick={() => handleEditClick(params.rowIndex)}
              />{'   '}
              {console.log('PARAMS----->',params)}
              <Link to={`./profile/${params?.row?.email}`} >
                <LaunchIcon/>
              </Link>
            </>
          );
        }
      },
    },
  ];

  const rows = profileData.map((profile, index) => ({
    id: index, // Use the index as a unique identifier
    ...profile,
  }));

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                All Profiles
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={() => window.print()}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
              >
                Download
              </Button>
              <Button
                className="bg-indigo-500 text-white active-bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleProfileModalOpen}
                variant="contained"
              >
                Add Profile
              </Button>
            </div>
          </div>
        </div>
        <div style={{ height: 730, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter={true}
            checkboxSelection
            onSelectionModelChange={handleSelect}
            onEditCellChangeCommitted={(params) => {
              // Handle cell edit commit here
              const updatedProfileData = [...profileData];
              updatedProfileData[params.rowIndex][params.field] =
                params.props.value;
              setProfileData(updatedProfileData);
            }}
          />
        </div>
      </div>
      <Modal open={openProfileModalForm} onClose={handleProfileModalClose}>
        <ProfileForm
          onClose={handleProfileFormClose}
          onProfileAdd={handleProfileAdd}
        />
      </Modal>
    </>
  );
}
