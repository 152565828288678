import React, { useState } from "react";
import { Button, Modal } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Moment from "moment";
import { DataGrid, GridToolbar, GridFilterModel } from "@mui/x-data-grid"; // Import DataGrid
import ProcessForm from "views/admin/AddProcessForm";
import CardSubProcess from "./CardSubProcess";
// import AddProcessForm from "views/admin/AddProcessForm";
import { useGetProcessQuery } from "redux/features/services/process";
import { useUpdateProcessMutation } from "redux/features/services/process";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import "./CardProcess.css"
import { saveAs } from "file-saver";
import ProfileFinder from "components/utils/ProfileFinder";
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom/cjs/react-router-dom";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MasterFilter } from "components/QueryBuilder/MasterFilter";
import { DialogContent, DialogActions, DialogTitle } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";


export default function CardProcess({
  processDataProps,
  subProcessData,
  // editMode
}) {
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("CREATED");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [openSubProcessModalForm, setopenSubProcesstModalForm] =
    useState(false);
  const [currProcess, setCurrProcess] = useState("");
  const [currProcessData, setCurrProcessData] = useState("");
  const [openProcessModalForm, setopenProcessModalForm] = useState(false);
  const [updateProcessMutation, isLoading] = useUpdateProcessMutation();
  const [editableRow, setEditableRow] = useState(null);
  const [queryOptions, setQueryOptions] = useState({});
  const [query, setQuery] = useState();
  const[filterApplied, setFilterApplied] = useState(false);
  const [editingProcess, setEditingProcess] = useState(null);

  // const { data: processData, refetch: refetchProcess, isLoading: isProcessLoading } = useGetProcessQuery({ page: 0, size: 25, queryOptions });
  const { data: processData, refetch: refetchProcess, isLoading: isProcessLoading } = useGetProcessQuery({ filter: query, page: 0, size: 25 });

  const resetFilter = () => {
    setQuery();
    setFilterApplied(false);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleQuery = (queryJson) => {
    console.log('Query builder response: ',queryJson);
    handleClose();
    setFilterApplied(true);
    setQuery(queryJson);
  };

  const handleProcessModalOpen = () => {
    setopenProcessModalForm(true);
  };

  const handleProcessModaleClose = () => {
    setopenProcessModalForm(false);
    setEditMode(false);
    refetchProcess({ page: 0, size: 25 });
  };

  const handleProcessFormClose = () => {
    handleProcessModaleClose();
    setEditMode(false);
  };

  const handlesubprocessModalOpen = (process) => {
    setCurrProcess(process?.id);
    setCurrProcess(process?.id);
    setCurrProcessData(process);
    setopenSubProcesstModalForm(true);
  };

  const handlesubprocesstModaleClose = () => {
    setopenSubProcesstModalForm(false);
  };

  const handleEditProcessModaleOpen = (process) => {
    setEditingProcess(process);
    setopenProcessModalForm(true);
    setEditMode(true);
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleCandidateEmailChange = (event) => {
    setCandidateEmail(event.target.value);
  };

  const handleAddProcess = (newProcess) => {
    handleProcessModaleClose();
  };

  const handleSubProcessUpsert = () => {
    refetchProcess();
  };

  const handleEditClick = (index) => {
    setEditableRow(index);
  };

  const updateProcess = async (rowIdx, updatedData) => {
    console.log('Profile updating for id: ',rowIdx,' data: ',updatedData);
    try {
      await updateProcessMutation(updatedData)
        .unwrap()
        .then((payload) => {
          console.log('Profile updated for id: ',rowIdx,' response'. payload);
          let updatedDataArray = [...processData?.content];
          updatedDataArray[rowIdx] = payload;
          console.log('Updated process Data state: ',updatedDataArray);
          setEditableRow(null);
        })
        .catch((err) => {
          console.error('Error updating Process', err)
          return;
        });
    } catch (error) {
      console.error("Error updating process data:", error);
      throw error;
    }
  };


  const handleDownload = () => {
    function convertToCSV(data) {
      const header = columns.map((column) => column.field);
      const rows = data.map((item) => header.map((field) => item[field]));
      const csvContent = [
        header.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "table_data.csv");
    }

    convertToCSV(processData?.content);
  };

  const columns = [
    { field: "client", headerName: "Client", flex: 1.3, editable: true,headerClassName: "custom-header" },
    {
      field: "clientPoc",
      headerName: "Point Of Contact",
      flex: 1.3,
      editable: true,
      headerClassName: "custom-header"
    },
    {
      field: "profileCode",
      headerName: "Profile Code",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
      valueFormatter: (params) => params.value?.split('@')[0]
    },
    {
      field: "clientCell",
      headerName: "Phone Number",
      flex: 1,
      editable: true,
      headerClassName: "custom-header"
    },
    { field: "source", headerName: "Source", flex: .7, editable: true ,headerClassName: "custom-header"},
    { field: "rate", headerName: "Rate", flex: 1, editable: true,headerClassName: "custom-header" },
    { field: "status", headerName: "Status", flex: 1, editable: true,headerClassName: "custom-header" },
    {
      field: "lastModifiedDate",
      headerClassName: "custom-header",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) => {
        return new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(Moment(params.value).toDate());
      },
    },
    {
      field: "actions",
      headerName: "Action",
      headerClassName: "custom-header",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "9px" }}>
            <VisibilityIcon
              onClick={() => handlesubprocessModalOpen(params.row)}
              style={{ cursor: "pointer" }}
            />
            {editableRow === params.tabIndex ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateProcess(params.tabIndex, params.row)}
              >
                Save
              </Button>
            ) : (
              <EditIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleEditClick(params.tabIndex)}
              />
            )}
            <Link 
              to={`./process/${params.row.id}`}
            >
              <LaunchIcon />
            </Link>
          </div>
        );
      },
    },
  ];

  console.log("we are getting data from profile compoent: ", processData?.content,'loading...',isProcessLoading);
  const rows = () => processData?.content.map((process, index) => ({
    id: index,
    ...process,
  }));

  const fields = [
    { name: 'lastModifiedBy', label: 'Lead Generated By' },
    { name: 'lastModifiedDate', label: 'Created Date' },
    { name: 'client', label: 'Client' },
    { name: 'clientEmail', label: 'Client Email' },
    { name: 'clientCell', label: 'Client Cell' },
    { name: 'client', label: 'Client' },
    { name: 'source', label: 'Source' },
    { name: 'status', label: 'Status' },
    { name: 'candidate.email', label: 'Profile' },
    { name: 'consultant', label: 'Consultant' },
    { name: 'tags', label: 'Tags' }
  ];

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                All Process
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <Button
                variant="contained"
                style={{ margin: "3px" }}
                onClick={handleOpen}
                startIcon={<FilterAltIcon />}
              >
                Filter
              </Button>
              {filterApplied && <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={resetFilter}
                startIcon={<FilterAltOffIcon />}
              >
                Reset
              </Button>}
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={() => window.print()}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
              >
                Download
              </Button>
              <Button variant="contained" onClick={handleProcessModalOpen}>
                Add Process
              </Button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <div style={{ height: 730, width: "100%" }}>
            {isProcessLoading ? 'Loading...' : 
              <DataGrid
                rows={rows()}
                columns={columns}
                loading={isProcessLoading}
                hideFooter={true}
                checkboxSelection
                onEditCellChangeCommitted={(params) => {
                  const updatedDataArray = [...processData?.content];
                  updatedDataArray[params.rowIndex][params.field] =
                    params.props.value;
                }}
              />
            }
          </div>
        </div>
        <Modal open={openProcessModalForm} onClose={handleProcessModaleClose}>
          <ProcessForm
            onClose={handleProcessFormClose}
            editingProcess={editMode ? editingProcess : {}}
            editMode={editMode}
          />
        </Modal>
      </div>
      <Modal
        open={openSubProcessModalForm}
        onClose={handlesubprocesstModaleClose}
      >
        <CardSubProcess
          processId={currProcess}
          processData={currProcessData}
          subProcessData={subProcessData}
          onSubProcessUpsert={handleSubProcessUpsert}
        />
      </Modal>
      <Modal style={{display:'flex',alignItems:'center',justifyContent:'center', width:'100%'}} open={open} onClose={handleClose}>
        <div>
          <DialogTitle id="id">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>Query Builder</Box>
              <Box>
                <IconButton onClick={(e) => handleClose(e, "BUTTON")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <MasterFilter handleQuery={handleQuery} filterOptions={fields} />
          </DialogContent>
          <DialogActions></DialogActions>
        </div>
      </Modal>
    </>
  );
}
