import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const subProcessApi = createApi({
  reducerPath: "subProcessApi",
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BPM_API }),
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSubProcess: builder.query({
      query: ({ processId }) => `/api/v1/bpm/sub-process/${processId}`,
    }),
    createSubProcess: builder.mutation({
      query: (subProcess) => (
        // console.log("we got hit in create sub process mutation"),
        {
        url: "/api/v1/bpm/sub-process",
        method: "POST",
        body: subProcess,
      }),
    }),

    updateSubProcess: builder.mutation({
      query: (subProcess) => ({
        url: "/api/v1/bpm/sub-process",
        method: "PATCH",
        body: subProcess,
      }),
    }),

    getSubProcessV2: builder.query({
      query: ({ page, size, jsonQuery }) => ({
        url: `api/v1/bpm/sub-process/filter`,
        method: "POST",
        body: jsonQuery,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
  }),
});

export const {
  useGetSubProcessQuery,
  useCreateSubProcessMutation,
  useGetSubProcessV2Query,
  useUpdateSubProcessMutation,
} = subProcessApi;
