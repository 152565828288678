import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from '@mui/material/Autocomplete';
import { MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useRegisterUserMutation } from "redux/features/authenticate/registerUser.service";
import { setAccessToken, setRefreshToken, setUser } from "redux/features/authenticate/authSlice";
import { useGetConfigQuery } from "redux/features/services/config.serivce";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Register() {

  const [userInput, setUserInput] = useState({ firstName: "", lastName: "", email: "", mobile: "", password: "", type: "" })
  const { firstName, lastName, email, mobile, password, type } = userInput;

  const [privacy, setPrivacy] = useState(false)
  const history = useHistory();
  const [firstNameErr, setFirstNameErr] = useState("")
  const [lastNameErr, setLastNameErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [mobileErr, setMobileErr] = useState("")
  const [passwordErr, setPasswordErr] = useState("")
  const [typeErr, setTypeErr] = useState("")
  const [privacyErr, setPrivacyErr] = useState("")
  const [responseErr, setResponseErr] = useState([])

  const dispatch = useDispatch();
  const [registerUser] = useRegisterUserMutation();
  // const { data: config } = useGetConfigQuery("PROFILE");

  const validateForm = () => {
    let isValid = true;

    if (firstName.trim() === "") {
      setFirstNameErr("First Name is required");
      isValid = false;
    } else {
      setFirstNameErr("");
    }

    if (lastName.trim() === "") {
      setLastNameErr("Last Name is required");
      isValid = false;
    } else {
      setLastNameErr("");
    }

    if (email.trim() === "") {
      setEmailErr("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailErr("Invalid email format");
      isValid = false;
    } else {
      setEmailErr("");
    }

    if (mobile.trim() === "") {
      setMobileErr("Mobile is required");
      isValid = false;
    } else {
      setMobileErr("");
    }

    if (password.trim() === "") {
      setPasswordErr("password is required");
      isValid = false;
    } else {
      setPasswordErr("");
    }

    if (privacy === false) {
      setPrivacyErr("Please click on agree privacy");
      isValid = false;
    } else {
      setPrivacyErr("");
    }

    // if (type.trim() === "") {
    //   setTypeErr("Profile Type is required");
    //   isValid = false;
    // } else {
    //   setTypeErr("");
    // }

    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserInput({ ...userInput, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    console.log("registration details are", userInput);

    try {
      setResponseErr([])
      const response = await registerUser(userInput);
      if (response?.error?.status === 401) {
        setResponseErr([...responseErr, response.error.data.message]);
      } else if (response?.error?.status === 400) {
        setResponseErr([...responseErr, response.error.data.message?.errors]);
      }
      dispatch(setAccessToken(response.data.access_token));
      dispatch(setRefreshToken(response.data.refresh_token));
      dispatch(setUser(response.data.user));

      setUserInput({ firstName: "", lastName: "", email: "", mobile: "", password: "", type: "" })
      setPrivacy(false)
      history.push("/admin")
    } catch (error) {
      console.error("Failed to create profile", error);
      if (error.response && error.response.data && error.response.data.message) {
        console.log("API Error:", error.response.data.message);
      }
    }
  }

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 register_page_dv">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div className="relative w-full mb-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Name"
                      value={firstName}
                      name="firstName"
                      onChange={handleChange}
                    />
                    {firstNameErr && <span className="text-red-500 text-xs">{firstNameErr}</span>}
                  </div>

                  <div className="relative w-full mb-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Name"
                      value={lastName}
                      name="lastName"
                      onChange={handleChange}
                    />
                    {lastNameErr && <span className="text-red-500 text-xs">{lastNameErr}</span>}
                  </div>

                  <div className="relative w-full mb-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      name="email"
                      onChange={handleChange}
                    />
                    {emailErr && <span className="text-red-500 text-xs">{emailErr}</span>}
                  </div>

                  <div className="relative w-full mb-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mobile
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="mobile"
                      value={mobile}
                      name="mobile"
                      onChange={handleChange}
                    />
                    {mobileErr && <span className="text-red-500 text-xs">{mobileErr}</span>}
                  </div>

                  <div className="relative w-full mb-6">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      name="password"
                      onChange={handleChange}
                    />
                    {passwordErr && <span className="text-red-500 text-xs">{passwordErr}</span>}
                  </div>
                  {/* <TextField
                    select
                    value={type}
                    name="type"
                    onChange={handleChange}
                    label="Profile Type"
                    variant="outlined"
                    error={!!typeErr}
                    helperText={typeErr}
                  >
                    {config &&
                      config.store.TYPE.map((t) => (
                        <MenuItem key={t} value={t}>
                          
                          {t}
                        </MenuItem>
                      ))}
                  </TextField> */}
                  {/* <Autocomplete
                    options={config?.store?.TYPE?.map(t=> t)}
                    // onChange={(e, newValue) => {
                    //   if (!newValue) {
                    //     setTypeError('Profile Type is required');
                    //   } else {
                    //     setTypeError('');
                    //   }
                    //   setType(newValue);
                    // }}
                    renderOption={(props, options) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {options}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={type}
                        label="Profile Type"
                        variant="outlined"
                        error={!!typeErr}
                        helperText={typeErr}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  /> */}
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onClick={() => setPrivacy(!privacy)}
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        I agree with the{" "}
                        <a
                          href="#pablo"
                          className="text-lightBlue-500"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                    <div>{privacyErr && <span className="text-red-500 text-xs">{privacyErr}</span>}</div>
                  </div>
                  {(responseErr && responseErr.length > 0) && responseErr
                    .map(err => <div><span style={{ color: "#D8000C", backgroundColor: "#FFBABA", borderRadius: "5px", padding: "5px", fontWeight: "bold" }} className="text-red-500 text-xs">{err}</span><br /></div>)}
                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 shadow_lg_dv"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Create Account
                    </button>
                    <div class="w-1/2 text-right create_content"><a class="text-blueGray-200" href="/auth/login"><small>Log In</small></a></div>
                  </div> 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
