import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const processApi = createApi({
  reducerPath: "processApi",
  baseQuery: baseQueryWithReauth,  tagTypes: ["Process"],

  endpoints: (builder) => ({
    // getProcess: builder.query({
    //   query: ({ page, size }) =>
    //     `/api/v1/bpm/process/paged?page=${page}&size=${size}&sort=lastModifiedDate,desc`,
    // }),
    getProcess: builder.query({
      query: ({ filter, page, size }) =>({
        url: `api/v2/bpm/process/query?page=${page}&size=${size}&sort=lastModifiedDate,desc`,
        method: "POST",
        body: filter ? filter : undefined,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
    }),
    getProcessById: builder.query({
      query: ({ id }) =>
        `api/v2/bpm/process/${id}`
    }),
    createProcess: builder.mutation({
      query: (body) => ({
        url: "/api/v1/bpm/process",
        method: "POST",
        body,
      }),
    }),
    createProcessV2: builder.mutation({
      query: (process) => (
        {
        url: "/api/v2/bpm/process",
        method: "POST",
        body: process,
      }),
      invalidatesTags: ['Process'],
    }),
    updateProcess: builder.mutation({
      query: (process) => ({
        url: "/api/v1/bpm/process",
        method: "PATCH",
        body: process,
      }),
    }),
  }),
});
export const {
  useGetProcessQuery,
  useGetProcessByIdQuery,
  useCreateProcessMutation,
  useCreateProcessV2Mutation,
  useUpdateProcessMutation,
} = processApi;
