import React, { useState } from "react";
import '../../layouts/Login_page.css'
import { Link } from "react-router-dom";
import { useForgetPasswordMutation } from "redux/features/authenticate/registerUser.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ForgetPasswords() {

  const [email, setEmail] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const history = useHistory();
  const [forgetPassword] = useForgetPasswordMutation();

  const validateForm = () => {
    let isValid = true;

    if (email.trim() === "") {
      setEmailErr("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailErr("Invalid email format");
      isValid = false;
    } else {
      setEmailErr("");
    }
    return isValid;
  };

  const handleRedirect = () => {
    console.log("Password reset Link send successfull, redirecting...")
    // return (<Redirect replace form="/auth/login" to="/admin/dashboard" />);
    history.push("/login");
  }

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    try {
      setEmailErr("")
      const response = await forgetPassword({ userEmail: email });
      if (response?.error?.status === 404) {
        setEmailErr(response.error.data.message);
        return;
      }
      handleRedirect();
    } catch (error) {
      console.error("Forget password operation failed", error);
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full forget_pass">
        <div className="flex content-center items-center justify-center h-full ">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 main_shadow_dv ">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold text_b">
                    Forget Password
                  </h6>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 login_form_dv">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2 E_label"
                      htmlFor="grid-password "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailErr && <span className="text-red-500 text-xs">{emailErr}</span>}
                  </div>
                  <div>
                    <p>
                      We’ll send a verification code to this email or phone number if it matches an existing BPM account.
                    </p>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 shadow_lg_dv"
                      type="button"
                      onClick={handleForgetPassword}
                    >
                      Send Reset Link
                    </button>
                  </div>
                  <div className="flex flex-wrap mt-3 relative">
                    <div className="w-1/2 text-right create_content">
                      <Link to="/auth/login" className="text-blueGray-200">
                        <small>Back</small>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
