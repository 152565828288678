import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import img from "./images/profile_img.jpg";
import ProfileImage from "../../assets/img/ProfileImage.png"
import "./Profile.css";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useGetSearchedQuery } from "redux/features/services/search.service";
import Modal from "@mui/material/Modal";
import ProcessForm from "./AddProcessForm";
import ProfileFinder from "components/utils/ProfileFinder";
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom/cjs/react-router-dom";

const Card_Association=({associationData})=>{
  const {client, profileCode, consultant, tags} = associationData
  return (
      <>
      {console.log('Card_Association---',associationData)}
             <div className="pro_box">
                 <div className="left_dtl">
                   <h4>
                     <b>Client</b>
                   </h4>
                   <h4>
                     <b>Profile</b>
                   </h4>
                   <h4>
                     <b>Assigned To </b>
                   </h4>
                   <h4>
                     <b>Tags</b>
                   </h4>
                 </div>
               <div className="right_dtl">
                       <p>{client}</p>
                       <p><ProfileFinder id={profileCode} resolver={(data)=>data['firstName']+" "+data['lastName']} /></p>
                       <p>{consultant}</p>
                       <p>{tags}</p>
             </div>
           </div>
      </>
    )
}

const Process_Row=({rowData})=>{
  const {id,client,clientEmail,profileCode,consultant,rate,status, tags} = rowData;
 return( 
 <>
          <tr>
          <td >{client}</td>
          <td >{clientEmail}</td>
          <td ><ProfileFinder id={profileCode} resolver={(data)=>data['firstName']+" "+data['lastName']} /></td>
          <td ><ProfileFinder id={consultant} resolver={(data)=>data['firstName']+" "+data['lastName']} /></td>
          <td >{rate}</td>
          <td >{status}</td>
          <td >{tags}</td>
          <td>
          <Link 
              to={`/admin/process/${id}`}
            >
              <LaunchIcon />
            </Link>
          </td>
        </tr>   
</>)
}

const Card_Upcoming=({upcomingData})=>{
  const {client, createdBy, profileCode, attendeeCode, interviewDate, time,timeZone, status} = upcomingData
  return(
    <>
      <div className="pro_box">
              <div className="left_dtl">
                <h4>
                  <b>Client</b>
                </h4>
                <h4>
                  <b>Profile</b>
                </h4>
                <h4>
                  <b>Attendee</b>
                </h4>
                <h4>
                  <b>Date </b>
                </h4>
                <h4>
                  <b>Time </b>
                </h4>
                <h4>
                  <b>Status</b>
                </h4>
              </div>
              <div className="right_dtl">
                <p>{client}</p>
                <p><ProfileFinder id={profileCode} resolver={(data)=>data['firstName']+" "+data['lastName']} /></p>
                <p><ProfileFinder id={attendeeCode} resolver={(data)=>data['firstName']+" "+data['lastName']} /></p>
                <p>{interviewDate}</p>
                <p>{time+" "+timeZone?.toUpperCase()}</p>
                <p>{status}</p>
              </div>
        </div>
    </>
  )
}

// const profileData={
//   profileName: "Akash Tomer",
//   profileMail:"akash.tomer@bluethink.in",
//   profileNumber:"8630408593",
//   association : [
//   {
//     client:"Emids",
//     profile:"Sachin",
//     assignedTo:"Akash",
//     tags:"JavaScript",
//   },
//   {
//     client:"Exarca",
//     profile:"anish",
//     assignedTo:"manisha",
//     tags:"Python",
//   },
//   {
//     client:"Neon",
//     profile:"harikesh",
//     assignedTo:"deepak",
//     tags:"Java",
//   },
// ],
//   upcoming : [
//     {
//     client:"Emids",
//     profile:"sachin",
//     date:"30/nov/2023",
//     time:"3:45"+"PM",
//     tags:"React",
//   },
//   {
//     client:"Exarca",
//     profile:"anish",
//     date:"14/dec/2023",
//     time:"3:45"+"PM",
//     tags:"Python"
//   },
//   {
//     client:"Neon",
//     profile:"harikesh",
//     date:"14/jan/2024",
//     time:"3:45"+"PM",
//     tags:"Magento"
//   },
//   ],
// }

function Profile() {

const [contactData, setContactData] = useState([])
const [profileData, setProfileData] = useState([])
const [processData, setProcessData] = useState([])
const [subProcessData, setSubProcessData] = useState([])
const [categoryName, setCategoryName] = useState('')
const [currentProfile, setCurrentProfile] = useState([]);

const paramsData = useParams()
const { id } = paramsData
const {data, isLoading, isSuccess, isError}= useGetSearchedQuery(id);

useEffect(() => {
  setCategoryName('');
  if (isSuccess && data) {
    data?.forEach((dataGroup) => {
      const category = Object.keys(dataGroup)[0];
      const value = dataGroup[category];
      if (category === "PROCESS") {
        setProcessData(value);
      } else if (category === "SUB-PROCESS") {
        const sortedSubProcessData = value.slice().sort((a, b) => {
          return new Date(a.interviewDate) - new Date(b.interviewDate);
        });
        setSubProcessData(sortedSubProcessData);
      } else if (category === "CONTACT") {
        setContactData(value);
        setCurrentProfile(value);
        setCategoryName("CONTACT")
      } else if(category === "PROFILE") {
        setProfileData(value)
        setCategoryName("PROFILE")
        setCurrentProfile(value);
      }
    });
  }
},[isSuccess, data])

console.log("process",processData,"contact data",contactData, "profile data",contactData,"sub process data",subProcessData)

  // const [selectedValues, setSelectedValues] = useState({
  //   a: false,
  //   b: false,
  //   c: false,
  //   d: false,
  //   e: false,
  // });

  const [openProcessModalForm, setopenProcessModalForm] = useState(false);
  // const [processData, setProcessData] = useState(initialProcessData);

  const handleProcessModalOpen = () => {
    setopenProcessModalForm(true);
  };

  const handleProcessModaleClose = () => {
    setopenProcessModalForm(false);
  };
  const handleAddProcess = (newProcess) => {
    handleProcessModaleClose();
  };

  const handleProcessFormClose = () => {
    handleProcessModaleClose();
  };

  return (
    <div>
      <div className="profile_data_main_wrapper">
        <div className="profile_details_wrapper">
          <div className="img_box">
            <img src={ProfileImage} alt="img" />
          </div>
          <div className="profile_name_box">
            {categoryName==='CONTACT' ? <h2>{contactData[0]?.firstName+ " " +contactData[0]?.lastName}</h2> :
             <h2>{profileData[0]?.firstName+ " " +profileData[0]?.lastName}</h2>}
            <Button onClick={handleProcessModalOpen}>
              <AddCircleOutlineIcon />
            </Button>
            <Modal
              open={openProcessModalForm}
              onClose={handleProcessModaleClose}
            >
              <ProcessForm
                onClose={handleProcessFormClose}
                onAddProcess={handleAddProcess}
                profileDetails={categoryName==='CONTACT' ? contactData[0] : profileData[0]}
                userCategory={categoryName}
              />
            </Modal>            
          </div>
          <div className="dropdown_dv">
            <div className="dropdown">
              <button className="dropbtn">
                {/* <ArrowDropDownIcon /> */}
                Client Details
              </button>
              <span className="right_icon">
                <EditIcon />
              </span>
              <div className="dropdown-content">
                <div className="open_drop_dv">
                  <div className="left_drop_item">
                    <h3>
                      <b>Email</b>
                    </h3>
                    <h3>
                      <b>Cell</b>
                    </h3>
                  </div>
                  <div className="right_drop_item">
                    <a href="#">{categoryName==='CONTACT' ? contactData[0]?.email : profileData[0]?.email}</a>
                    <a href="#">{categoryName==='CONTACT' ? contactData[0]?.mobile : profileData[0]?.mobile }</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button {...bindTrigger(popupState)}>
                      Tags
                    </Button>

                    <span className="right_icon">
                      <AddIcon />
                    </span>
                    
                  </React.Fragment>
                )}
              </PopupState>
            </div>
            <div className="dropdown">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button {...bindTrigger(popupState)}>
                      <ArrowDropDownIcon />
                      Assigned Experts
                    </Button>
                    <span className="right_icon">
                      <EditIcon />
                    </span>
                    <Menu {...bindMenu(popupState)}>
                      <button onClick={popupState.close}>React</button>
                      <button onClick={popupState.close}>Ui Dev.</button>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
            <div className="dropdown">
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button {...bindTrigger(popupState)}>
                      <ArrowDropDownIcon />
                      Notes
                    </Button>
                    <span className="right_icon">
                      <AddCircleOutlineIcon />
                    </span>
                    <Menu {...bindMenu(popupState)}>
                      <textarea
                        id="myTextarea"
                        placeholder="Click me to expand"
                        onclick="expandTextarea()"
                      ></textarea>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
          </div>
        </div>
        <div className="profile_data_rgt_dv_tp">
        <div className="profile_data_rgt_dv">
          <div className="top_head_dv">
            <h3>Recent Process (Last 5)</h3>
            <Button variant="text">Show all</Button>
          </div>
          
         <div className="Process_data-table">

         <table class="table-resposives">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Email</th>
                  <th>Profile</th>
                  <th>Consultant</th>
                  <th>Rate</th>
                  <th>Status</th>
                  <th>Tags</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {processData?.map((dataCard)=>(
                <Process_Row rowData={dataCard}/>
               )
              )}
                </tbody>
            </table>
         </div>

          </div>
          <div className="details_down_dv">
            <div className="top_head_dv">
              <h3>Association - On Going</h3>
              <Button variant="text">Show all</Button>
            </div>
          </div>
          <div className="multiple_box_prfile">
            {
              processData?.map((dataCard)=>(
                <Card_Association associationData={dataCard}/>
              ))
            }

          </div>
          <div className="details_down_dv">
            <div className="top_head_dv">
              <h3>Upcoming Events</h3>
            </div>
          </div>
          <div className="multiple_down_box_profile">
            {console.log("we are getting subProcess data data",subProcessData)}
            {
              subProcessData?.filter(sp=>(new Date() - new Date(sp.interviewDate)) <= 0).slice(0,3).map((dataCard)=>(
                <Card_Upcoming upcomingData={dataCard} />
              ))
            }
          </div> 
        </div>
      </div>
    </div>
  );
}

export default Profile;
 