import React, { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DateRangeDropdown from "components/Dropdowns/DateRangeDropdown";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { useDispatch } from "react-redux";
import { logout } from "redux/features/authenticate/authSlice";
import { Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Link from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./AdminNavbar.css"
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from "react-redux";

export default function Navbar() {
  const [profileToggle, setProfileToggle] = useState(false);
  const dispatch = useDispatch();
  const loggedInUser = useSelector((store) => store.authSlice.user?.email);
  const dateRange = (range) => {
    console.log("Navbar----->>>>>>", range);
  };

  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleLogOut = () => {
    dispatch(logout());
  };

  const handleProfile = () => {
    setProfileToggle(!profileToggle);
  };

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {location.pathname === "/admin/masterdata" ? (
              <p>Master Filter</p>
            ) : location.pathname === "/admin/profiles" ? (
              <p>profiles</p>
            ) : location.pathname === "/admin/profile" ? (
              <p>User Profile</p>
            ) : location.pathname === "/admin/process" ? (
              <p>process</p>
            ) : location.pathname === "/admin/contact" ? (
              <p>Contact</p>
            ) : (
              <p>dashboard</p>
            )}
          </a>
          {/* Form */}          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              {/* <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-search"></i>
                </span>
                <input
                type="text"
                placeholder="Search here..."
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
              /> */}
            </div>
          </form>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <DateRangeDropdown handleDateRangeChange={dateRange} />
          </ul>
          <Button
            className="userProfileBtn"
            style={{ margin: "2px", color: "#FAF9F6", border: "none", position:"relative" }}
            onClick={handleProfile}
          >
            <AccountCircleIcon sx={{ color: "action", fontSize: 58 }} />
          </Button>
          {profileToggle ? (
            <div className="profileList">
              <ul className="profileUnorderList">
                <li style={{ color: "#FAF9F6", margin:"5px 0"}}>{loggedInUser}</li>
                <li>
                  <Button>
                    <Link
                      to="/admin/profile"
                      style={{ color: "#FAF9F6", margin:"2px 0"}}
                      variant="outlined"
                      >
                        <i
                    className={
                      "fas fa-table mr-2 text-3m opacity-75"
                    }
                  ></i>
                      Profile
                    </Link>
                  </Button>
                </li>
                <li>
                  <Button
                    style={{color: "#FAF9F6", margin:"3px 0"}}
                    onClick={handleLogOut}
                    // startIcon={<LogoutOutlined />}
                  >
                    <LogoutIcon className="opacity-75 mr-1"/>
                     Logout
                  </Button>
                </li>
              </ul>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
