import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import MasterData from "views/admin/MasterData.js";
import Profiles from "views/admin/Profiles";
import ProcessData from "views/admin/ProcessData";
import Contact from "views/admin/Contact";
import Profile from "views/admin/Profile";
import ProcessDetails from "views/admin/ProcessDetails";
import { useSelector } from "react-redux";
import { decodeJwt } from "redux/features/authenticate/SecureRequest";
import { isDevelopment } from "components/utils/Constant";

export default function Admin() {
  const token = useSelector((store) => store.authSlice.accessToken);
  const decodedToken = decodeJwt(token);
  const dateNow = new Date();
  const location = useLocation();

  const renderContent = (<>
    <Sidebar />
    <div className="relative md:ml-64 bg-blueGray-100">
     <AdminNavbar />
     <HeaderStats />
      <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <Switch>
          <Route path="/admin/dashboard" exact component={Dashboard} />
          <Route path="/admin/profiles" exact component={Profiles} />
          <Route path="/admin/process" exact component={ProcessData} />
          <Route path="/admin/process/:id" exact component={ProcessDetails} />
          <Route path="/admin/masterdata" exact component={MasterData} />
          <Route path="/admin/contact" exact component={Contact} />
          <Route path="/admin/profile/:id" exact component={Profile} />
          <Redirect from="/admin" to="/admin/dashboard" />
        </Switch>
        {location.pathname==="/admin/dashboard"? (<FooterAdmin />): ("")} 
      </div>
    </div>
  </>);

  return (
    (token && (decodedToken.exp * 1000 > dateNow.getTime())) ?
      (isDevelopment ? renderContent : 
        (decodedToken?.authorities.includes('ROLE_PROD') ? renderContent :
          (<Redirect to="/auth/accessDenied" />))) 
    : (<Redirect to="/auth/login" />)
  );
}
