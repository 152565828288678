import React, { useState, useEffect } from "react";
import { useCreateProcessV2Mutation, useGetProcessQuery } from "../../redux/features/services/process";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { useGetConfigQuery } from "redux/features/services/config.serivce";
import { useGetProfilesQuery } from "../../redux/features/services/profile.serivce";
import Autocomplete from '@mui/material/Autocomplete';
import { isValidEmail } from "components/utils/Constant";
import { isValidPhone } from "components/utils/Constant";

function ProcessForm({ onClose, selectedContact, profileDetails, userCategory }) {
  const [client, setClient] = useState("");
  const [clientPoc, setClientPoc] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientCell, setClientCell] = useState("");
  const [source, setSource] = useState("");
  const [rate, setRate] = useState("");
  const [status, setStatus] = useState("");
  const [profileCode, setProfileCode] = useState("");
  const [createProcessV2, { isLoading }] = useCreateProcessV2Mutation();
  const { data: config } = useGetConfigQuery("PROCESS");
  const { data: profiles = [] } = useGetProfilesQuery({
    page: 0,
    size: 25,
    sort: "lastModifiedDate,desc",
  });
  const [interviewMode, setInterviewMode] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [attendeeCode, setAttendeeCode] = useState("");
  const [meetingInvite, setMeetingInvite] = useState("");
  const [time, setTime] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [note, setNote] = useState("");
  const [contactId, setContactId] = useState("");
  const [tags, setTags] = useState("");
  // const { refetch } = useGetProcessQuery()
 
  // Validation states
  const [clientError, setClientError] = useState("");
  const [clientPocError, setClientPocError] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");
  const [clientCellError, setClientCellError] = useState("");
  const [rateError, setRateError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [profileCodeError, setProfileCodeError] = useState("");
  const [sourceError, setSourceError] = useState("");
  const [interviewModeError, setInterviewModeError] = useState();
  const [timeError, setTimeError] = useState();
  const [timeZoneError, setTimeZoneError] = useState();
  const [noteError, setNoteError] = useState();
  const [meetingInviteError, setMeetingInviteError] = useState();
  const [interviewDateError, setInterviewDateError] = useState();
  const [attendeeCodeError, setAttendeeCodeError] = useState();
  const [formError, setFormError] = useState();

  useEffect(() => {
    if (profileDetails){
      if(userCategory==="CONTACT") {
        console.log('Creating process for Contact: ',profileDetails);
        setContactId(profileDetails.id);
        const client = profileDetails.email ? profileDetails.email.split('@')[1] : "";
        setClient(profileDetails.organization ? profileDetails.organization : client.split('@')[0]);
        setClientEmail(profileDetails.email);
        setClientCell(profileDetails.mobile);
        setClientPoc(profileDetails.firstName+" "+profileDetails.lastName);
      }else if(userCategory==="PROFILE"){
        console.log('Creating process for Profile: ',profileDetails);
        setProfileCode(profileDetails?.email);
      }
    }
  }, []);
  const validateForm = () => {
    let isValid = true;
    if (client.trim() === "") {
      setClientError("Client is required");
      isValid = false;
    } else {
      setClientError("");
    }
    // if (clientPoc.trim() === "") {
    //   setClientPocError("Point Of Contact is required");
    //   isValid = false;
    // } else {
    //   setClientPocError("");
    // }
    if ((clientEmail.trim() !== "") && (!isValidEmail.test(clientEmail))) {
      console.log("Client email: ", clientEmail, "is valid: ",isValidEmail.test(clientEmail));
      setClientEmailError("Please enter a valid email.");
      isValid = false;
    } else {
      setClientEmailError("");
    }
    if (clientCell.trim() !== "" && (!isValidPhone(clientCell.trim()))) {
      console.log("Client cell: ", clientCell, "is valid: ",isValidPhone(clientCell.trim()));
      setClientCellError("Please enter a velid phone number.");
      isValid = false;
    } else {
      setClientCellError("");
    }

    if (rate.trim() === "") {
      setRate('0');
    } 
    if (status.trim() === "") {
      setStatusError("Status is required");
      isValid = false;
    } else {
      setStatusError("");
    }
    if (profileCode.trim() === "") {
      setProfileCodeError("Profile Code is required");
      isValid = false;
    } else {
      setProfileCodeError("");
    }
    if (source.trim() === "") {
      setSourceError("Source is required");
      isValid = false;
    } else {
      setSourceError("");
    }
    if (interviewMode.trim() === "") {
      setInterviewModeError("Interview Mode is required");
      isValid = false;
    } else {
      setInterviewModeError("");
    }

    if (interviewDate.trim() === "") {
      setInterviewDateError("Interview Date is required");
      isValid = false;
    } else {
      setInterviewDateError("");
    }

    if (time.trim() !=="" && timeZone.trim() === "") {
      console.log('Time set to: ',time);
      setTimeZoneError("Time zone is required");
      isValid = false;
    } else {
      setTimeZoneError("");
    }
    
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
        setFormError('');
        await createProcessV2({
          client,
          clientPoc,
          clientEmail,
          clientCell,
          source,
          rate,
          status,
          // profileCode: profileDetails && profileDetails.code? profileDetails.code : profileCode,
          profileCode,
          attendeeCode,
          interviewMode,
          interviewDate,
          time,
          timeZone,
          meetingInvite,
          note,
          contactId,
          tags
        })
        .unwrap()
        .then((payload) => {
          console.log('Profile created...', payload);
          setFormError('');
          // refetch();
          onClose();
        })
        .catch((err) => {
          console.error('Error creating Process', err)
          setFormError(err?.data?.message);
          return;
        })
      } catch (err) {
        console.error('Error creating Process-----', err)
        return;
      }

      // onAddProcess({
      //   client,
      //   clientPoc,
      //   clientEmail,
      //   clientCell,
      //   source,
      //   rate,
      //   status,
      //   profileCode,
      // });
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 550,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          zIndex: 99,
        }}
      >
        <Typography variant="h4" align="center">
          Add Process
        </Typography>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "16px",
            }}
          >
            <div>
              <Stack
                component="form"
                sx={{
                  width: "100%",
                }}
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <TextField
                  type="text"
                  value={client}
                  onChange={(e) => setClient(e.target.value)}
                  label="Client"
                  variant="outlined"
                  error={!!clientError}
                  helperText={clientError}
                />
                <TextField
                  type="text"
                  value={userCategory === "CONTACT"? (profileDetails.firstName +" "+ profileDetails.lastName) : clientPoc}
                  onChange={(e) => setClientPoc(e.target.value)}
                  label="Point Of Contact"
                  variant="outlined"
                  error={!!clientPocError}
                  helperText={clientPocError}
                />
                <TextField
                  type="email"
                  value={userCategory === "CONTACT"? (profileDetails?.email) : clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                  label="Client Email"
                  variant="outlined"
                  error={!!clientEmailError}
                  helperText={clientEmailError}
                />
                <TextField
                  type="text"
                  value={ userCategory === "CONTACT"? profileDetails?.mobile : clientCell}
                  onChange={(e) => setClientCell(e.target.value)}
                  label="Phone Number"
                  variant="outlined"
                  error={!!clientCellError}
                  helperText={clientCellError}
                />
                <TextField
                defaultValue={interviewDate}
                value={interviewDate}
                onChange={(e) => setInterviewDate(e.target.value)}
                variant="outlined"
                type="date"
                label="Date"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableunderline: true }}
                error={!!interviewDateError}
                helperText={interviewDateError}
                />
                <TextField
                value={time}
                onChange={(e) => setTime(e.target.value)}
                label="Time"
                variant="outlined"
                type="time"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableunderline: true }}
                error={!!timeError}
                helperText={timeError}
                />
                
                {/* <TextField
                  select
                  value={attendeeCode}
                  onChange={(e) => setAttendeeCode(e.target.value)}
                  label="Assigned to"
                  variant="outlined"
                  error={!!attendeeCodeError}
                  helperText={attendeeCodeError}
                >
                  {profiles?.body?.content?.map((profile) => (
                    <MenuItem key={profile.id} value={profile.email}>
                      {profile.email}
                    </MenuItem>
                  ))}
                </TextField> */}
                <Autocomplete
                  options={profiles?.body?.content?.map(t=> t.email)}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setAttendeeCodeError('Attendee Code is required');
                    } else {
                      setAttendeeCodeError('');
                    }
                    setAttendeeCode(newValue);
                  }}
                    renderOption={(props, options) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      defaultValue={"Hello"}
                      label="Assign To"
                      variant="outlined"
                      error={!!attendeeCodeError}
                      helperText={attendeeCodeError}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                <TextField
                value={tags}
                label="Comma Separeted Tags"
                variant="outlined"
                onChange={(e) => setTags(e.target.value)}
              />
              </Stack>
            </div>
            <div>
              <Stack
                component="form"
                sx={{
                  width: "100%",
                }}
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <TextField
                  type="text"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  label="Rate"
                  variant="outlined"
                  error={!!rateError}
                  helperText={rateError}
                />
                {/* <TextField
                  select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Select Status"
                  variant="outlined"
                  error={!!statusError}
                  helperText={statusError}
                >
                  {config &&
                    config.store.STATUS.map((st) => (
                      <MenuItem key={st} value={st}>
                        {st}
                      </MenuItem>
                    ))}
                </TextField> */}

                <Autocomplete
                  options={config?.store?.STATUS}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setStatusError('Status is required');
                    } else {
                      setStatusError('');
                    }
                    setStatus(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={status}
                      label="Select Status"
                      variant="outlined"
                      error={!!statusError}
                      helperText={statusError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                {/* {console.log("we got the value of profile code ", profileDetails?.code)} */}
                {/* <TextField
                  label="Profile Code"
                  variant="outlined"
                  // select
                  value={userCategory === "PROFILE"? profileDetails.code : profileCode}
                  onChange={(e) => setProfileCode(e.target.value)}
                  error={!!profileCodeError}
                  helperText={profileCodeError}
                >
                  {profiles?.body?.content?.map((profile) => (
                    <MenuItem key={profile.id} value={profile.code}>
                      {profile.code}
                    </MenuItem>
                  ))}
                </TextField> */}
                <Autocomplete
                  options={profiles?.body?.content?.map(t=> t.email)}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setProfileCodeError('Profile Code is required');
                    } else {
                      setProfileCodeError('');
                    }
                    setProfileCode(newValue);
                  }}
                    renderOption={(props, options) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      defaultValue={"Hello"}
                      label="Profile Code"
                      variant="outlined"
                      error={!!profileCodeError}
                      helperText={profileCodeError}
                      inputProps={{
                        ...params.inputProps,
                        // autoComplete: 'Select Profile', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                {/* <TextField
                  select
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  label="Select Source"
                  variant="outlined"
                  error={!!sourceError}
                  helperText={sourceError}
                >
                  {config &&
                    config.store.SOURCE.map((src) => (
                      <MenuItem key={src} value={src}>
                        {src}
                      </MenuItem>
                    ))}
                </TextField> */}

                <Autocomplete
                  options={config?.store?.SOURCE}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setSourceError('Profile Source is required');
                    } else {
                      setSourceError('');
                    }
                    setSource(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={source}
                      label="Select Source"
                      variant="outlined"
                      error={!!sourceError}
                      helperText={sourceError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                {/* <TextField
                value={interviewMode}
                onChange={(e) => setInterviewMode(e.target.value)}
                label="Interview Mode"
                variant="outlined"
                error={!!interviewModeError}
                helperText={interviewModeError}
                select
              >
                <MenuItem value="Zoom">Zoom</MenuItem>
                <MenuItem value="Google_Meet">Google Meet</MenuItem>
                <MenuItem value="Teams">Teams</MenuItem>
                <MenuItem value="RingCentral">RingCentral</MenuItem>
                <MenuItem value="Cisco_Webex">Cisco Webex</MenuItem>
                <MenuItem value="Skype">Skype</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField> */}

              <Autocomplete
                  options={config && config?.store?.MODE_OF_COMM}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setInterviewModeError('Profile Source is required');
                    } else {
                      setInterviewModeError('');
                    }
                    setInterviewMode(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={interviewMode}
                      label="Interview Mode"
                      variant="outlined"
                      error={!!interviewModeError}
                      helperText={interviewModeError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              <Autocomplete
                  options={config?.store?.TIMEZONE}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setTimeZoneError('Time Zone is required');
                    } else {
                      setTimeZoneError('');
                    }
                    setTimeZone(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={timeZone}
                      label="Time Zone"
                      variant="outlined"
                      error={!!timeZoneError}
                      helperText={timeZoneError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              <TextField
                value={meetingInvite}
                onChange={(e) => setMeetingInvite(e.target.value)}
                label="Meeting Invite"
                variant="outlined"
                error={!!meetingInviteError}
                helperText={meetingInviteError}
              />
              <TextField
                value={note}
                label="Note"
                variant="outlined"
                onChange={(e) => setNote(e.target.value)}
                error={!!noteError}
                helperText={noteError}
              />
              </Stack>
            </div>
          </div>
          <div>
              {formError && formError.length > 1 ? (<p style={{color: "red", fontWeight: "bold"}}>{formError}</p>): ""}
          </div>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            onClick={handleSubmit}
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              marginTop: "20px",
            }}
          >
            {isLoading ? "Adding..." : "Add"}
          </Button>
        </form>
      </Box>
    </> );
}
export default ProcessForm;