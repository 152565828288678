import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import './Login_page.css'

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ForgetPasswords from "views/auth/ForgetPassword";
import ResetPassword from "views/auth/ResetPassword";
import AccessDenied from "views/auth/AccessDenied";

export default function Auth() {
  return (
    <>
      {/* <Navbar transparent /> */}
      <main>
        <section className="relative w-full h-full py-40 min-h-screen ">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full login_page_back"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/forgetPassword" exact component={ForgetPasswords} />
            <Route path="/auth/resetPassword" exact component={ResetPassword} />
            <Route path="/auth/accessDenied" exact component={AccessDenied} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          {/* <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
}
