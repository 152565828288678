import React, { useState } from "react";
import CardSubProcess from "./CardSubProcess";
import "./CardProcess.css";
import "./CardProcessDetails.css";
import { useGetProcessByIdQuery } from "redux/features/services/process";
import Chip from '@mui/material/Chip';
import { formateDate } from "components/utils/Constant";
import { colorMap } from "components/utils/Constant";

export default function CardProcessDetails({processId}) {

  const { data: processData, isLoading: isProcessLoading, refetch: refetchProcessData } = useGetProcessByIdQuery({id: processId});

  const handleSubProcessMutation = ()=>{
    refetchProcessData();
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
         <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                PROCESS
              </h3>
            </div>
        </div>
        <div className="block w-full overflow-x-auto ">
            {
                !isProcessLoading && (
                  <ul className="unorder_list">
                    <li className="list"><span className="list_key">Client : </span>{processData.client}</li>
                    <li className="list"><span className="list_key">Client Email : </span>{processData.clientEmail}</li>
                    <li className="list"><span className="list_key">Point of contact : </span>{processData.clientPoc}</li>
                    <li className="list"><span className="list_key">Consultant : </span>{processData.consultant}</li>
                    <li className="list"><span className="list_key">Profile Code : </span>{processData.profileName}</li>
                    <li className="list"><span className="list_key">Phone Number : </span>{processData.clientCell}</li>
                    <li className="list"><span className="list_key">Source : </span>{processData.source}</li>
                    <li className="list"><span className="list_key">Rate : </span>{processData.rate}</li>
                    <li className="list"><span className="list_key">Status : </span><Chip label={processData.status} style={{background: colorMap[processData.status]}} /></li>
                    <li className="list"><span className="list_key">Tags : </span>{processData.tags ? processData.tags.split(',').map(t=>(<Chip label={t.trim()} color="primary" />)) : ""}</li>
                    <li className="list"><span className="list_key">Created By : </span>{processData.createdBy}</li>
                    <li className="list"><span className="list_key">Created on : </span>{formateDate(processData.creationDate)}</li>
                  </ul>
                )
            }
        </div>
        <div>
        <div className="rounded-t mb-0 px-4 py-3 border-0">
         <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
               SUB-PROCESS
              </h3>
              <div className="master_data_dv">
            <CardSubProcess 
                processId={processId} 
                processData={processData}
                onSubProcessUpsert={handleSubProcessMutation}
            />
            </div>
            </div>
        </div>
        </div>
      </div>
    </>
  );
}
