import React from 'react'
import CardProfiles from 'components/Cards/CardProfiles.js';

import { useGetProfilesQuery } from '../../redux/features/services/profile.serivce';

export default function Profiles() {

    const { data: profiles = [], isLoading } = useGetProfilesQuery({page: 0, size: 1000, sort: 'lastModifiedDate,desc'});

  return (
    <>
      <div className="flex flex-wrap mt-0">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-0">
          {isLoading ? (
              <p>Loading...</p>
              ) : (
                  <CardProfiles profileData={profiles?.body?.content} />
                  )}
        </div>
    </div>
    </>
  )
}
