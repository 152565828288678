import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchFilter.css";
import SearchResult from "views/admin/SearchResult";

  const handleInputClick = () => {
    const label = document.querySelector('#outlined-search-label'); 
    if (label) {
      label.style.display = 'none';
    }
  };

export default function SearchFilter () {
    
    const [searchValue, setSearchValue]= useState('')
    const [filterValue, setFilterValue]= useState('')
    const [flag, setFlag]= useState(false)
    
    const getData=(value)=>{
      setFilterValue(value)
    }

    const debounce= function (fn, d){
      let timer
      return function(...args){
          clearTimeout(timer)
          timer= setTimeout(()=>{
              fn(...args)
          }, d)
      }
    }

    const debouncedChange = debounce(getData, 2000);

    const handleSearchChange=(e)=>{
      const { value } = e.target;
      setSearchValue(value);
      if(value.length < 3){
        setFlag(false);
      }
        if (value.length >= 3) {
          setFlag(true);
          debouncedChange(value);
        }
    }

    return (
    <div className="search-box">
      <div className="main_search_in">
        <div className="Search_left_dv">
        <TextField
            id="outlined-search"
            label="Search Keywords"
            type="search"
            value={searchValue}
            onChange={handleSearchChange}
            InputLabelProps={{
              shrink: false, // Initially set to false
            }}
            onClick={handleInputClick}
          />
        </div>
        <div className="icons_dv">
          <button>
            <SearchIcon />
          </button>
          <div className="stand_brd"></div>
          <button>
            {" "}
            <FilterAltIcon />
          </button>
        </div>


      </div>
        <SearchResult filterValue={filterValue} flag={flag}/>
    </div>
  );
};