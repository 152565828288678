import React from 'react'
import CardProcess from 'components/Cards/CardProcess.js';
import { useGetProcessQuery } from 'redux/features/services/process';
import { useParams } from 'react-router-dom';
import CardProcessDetails from 'components/Cards/CardProcessDetails';

export default function ProcessDetails() {

    // const { data: process } = useGetProcessQuery({ page: 0, size: 1000 });
    const paramsData= useParams()
    // let filteredData = process?.body?.content.filter(item=> item.id === paramsData.id)
    return (
    <>
      <div className="flex flex-wrap mt-0">
       <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-0">
          {(!paramsData.id)? (
            <p>Loading...</p>
            ) : (
              <>
              <CardProcessDetails processId={paramsData.id}/>
              </>
              )}
        </div>
      </div>
    </>
  )
}
