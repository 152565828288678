import React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { useGetSubProcessQuery } from "redux/features/services/subProcess";
import { useGetProfilesQuery } from "../../redux/features/services/profile.serivce";
import { useGetConfigQuery } from "redux/features/services/config.serivce";
import Autocomplete from "@mui/material/Autocomplete";
import Moment from "moment";
import {
  useCreateSubProcessMutation,
  useUpdateSubProcessMutation,
} from "../../redux/features/services/subProcess";

export default function AddSubProcessForm({
  onClose,
  onAddSubProcess,
  onUpdateSubProcess,
  process,
  processData,
  editingSubProcess,
  editMode,
}) {
  // const {
  //   data: subProcessData = [],
  //   isLoading,
  //   refetch: refetchSubProcess,
  // } = useGetSubProcessQuery(process);

  const { data: profiles = [] } = useGetProfilesQuery({
    page: 0,
    size: 25,
    sort: "lastModifiedDate,desc",
  });
  const { data: config } = useGetConfigQuery("PROCESS");
  const [createSubProcess] = useCreateSubProcessMutation();
  const [id, setId] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [attendeeCode, setAttendeeCode] = useState("");
  const [interviewPanel, setInterviewPanel] = useState("");
  const [meetingInvite, setMeetingInvite] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientCell, setClientCell] = useState("");
  const [status, setStatus] = useState("");
  const [interviewMode, setInterviewMode] = useState("");
  const [time, setTime] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [note, setNote] = useState("");
  const [updateSubProcess] = useUpdateSubProcessMutation();

  const [interviewDateError, setInterviewDateError] = useState();
  const [attendeeCodeError, setAttendeeCodeError] = useState();
  const [interviewPanelError, setInterviewPanelError] = useState();
  const [meetingInviteError, setMeetingInviteError] = useState();
  const [clientEmailError, setClientEmailError] = useState();
  const [clientCellError, setClientCellError] = useState();
  const [statusError, setStatusError] = useState();
  const [interviewModeError, setInterviewModeError] = useState();
  const [timeError, setTimeError] = useState();
  const [timeZoneError, setTimeZoneError] = useState();
  const [noteError, setNoteError] = useState();

  const validateForm = () => {
    let isValid = true;

    if (!interviewDate) {
      // Check if interviewDate is empty
      setInterviewDateError("Date is required");
      isValid = false;
    } else {
      setInterviewDateError(""); // Clear the error message
    }

    // if (!attendeeCode) {
    //   setAttendeeCodeError("Attendee Code is required");
    //   isValid = false;
    // } else {
    //   setAttendeeCodeError("");
    // }

    if (interviewPanel.trim() === "") {
      setInterviewPanelError("Interview Panel is required");
      isValid = false;
    } else {
      setInterviewPanelError("");
    }

    if (meetingInvite.trim() === "") {
      setMeetingInviteError("Meeting Invite is required");
      isValid = false;
    } else {
      setMeetingInviteError("");
    }

    if (status.trim() === '') {
      setStatusError("Status is required");
      isValid = false;
    } else {
      setStatusError("");
    }

    if (!interviewMode) {
      setInterviewModeError("Interview Mode is required");
      isValid = false;
    } else {
      setInterviewModeError("");
    }

    if (!time) {
      setTimeError("Time is required");
      isValid = false;
    } else {
      setTimeError("");
    }

    if (!timeZone) {
      setTimeZoneError("Time Zone is required");
      isValid = false;
    } else {
      setTimeZoneError("");
    }

    // if (note.trim() === "") {
    //   setNoteError("Note is required");
    //   isValid = false;
    // } else {
    //   setNoteError("");
    // }

    return isValid;
  };

  const resetEditForm = () => {
    setId("");
    setInterviewDate("");
    setAttendeeCode("");
    setInterviewPanel("");
    setMeetingInvite("");
    setClientEmail("");
    setClientCell("");
    setStatus("");
    setInterviewMode("");
    setTime("");
    setTimeZone("");
    setNote("");
  };

  useEffect(() => {
    if (editMode) {
      console.log("Editing sub-process: ",editingSubProcess)
      setId(editingSubProcess.id);
      setInterviewDate(
        Moment(new Date(editingSubProcess.interviewDate)).format("YYYY-MM-DD")
      );
      setAttendeeCode(editingSubProcess.consultant);
      setInterviewPanel(editingSubProcess.interviewPanel);
      setMeetingInvite(editingSubProcess.meetingInvite);
      setClientEmail(editingSubProcess.clientEmail);
      setClientCell(editingSubProcess.clientCell);
      setStatus(editingSubProcess.status);
      setInterviewMode(editingSubProcess.interviewMode);
      setTime(Moment.utc(editingSubProcess.time, "HH:mm")._i);
      setTimeZone(editingSubProcess.timeZone);
      setNote(editingSubProcess.note);
    } else {
      resetEditForm();
    }
  }, []);

  const handleSubmit = async (e) => {
    console.log("Creating sub-process for process: ", process, " Form is valid: ",validateForm());
    if (processData && processData.consultant) {
      setAttendeeCode(processData.consultant);
    }
    if (processData && processData.clientEmail) {
      setClientEmail(processData.clientEmail);
    }
    if (processData && processData.clientCell) {
      setClientCell(processData.clientCell);
    }
    e.preventDefault();   
    if (!validateForm()) {
      return;
    }
    
    try {
      const response = await createSubProcess({
        processId: process,
        interviewDate,
        attendeeCode:
          processData && processData.consultant
            ? processData.consultant
            : attendeeCode,
        interviewPanel,
        meetingInvite,
        clientEmail:
          processData && processData.clientEmail
            ? processData.clientEmail
            : clientEmail,
        clientCell:
          processData && processData.clientCell
            ? processData.clientCell
            : clientCell,
        status,
        interviewMode,
        time,
        timeZone,
        note
      });

      onUpdateSubProcess({
        interviewDate,
        attendeeCode,
        interviewPanel,
        meetingInvite,
        clientEmail,
        clientCell,
        status,
        interviewMode,
        time,
        timeZone,
        note
      });
      setTimeout(() => {
        onClose();
      }, 1000);
      // refetchSubProcess(process);
    } catch (error) {
      console.error("Failed to update sub-process", error);
    }
  };

  const handleEditSubmit = async (e) => {
    console.log("Creating sub-process for process: ", process);
    e.preventDefault();
    try {
      const response = await updateSubProcess({
        id,
        processId: process,
        interviewDate,
        attendeeCode,
        interviewPanel,
        meetingInvite,
        clientEmail,
        clientCell,
        status,
        interviewMode,
        time,
        timeZone,
        note
      });

      onUpdateSubProcess({
        interviewDate,
        attendeeCode,
        interviewPanel,
        meetingInvite,
        clientEmail,
        clientCell,
        status,
        interviewMode,
        time,
        timeZone,
        note
      });
      resetEditForm();
    } catch (error) {
      console.error("Failed to update sub-process", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h4" align="center">
          {editMode ? "Edit Sub Process" : "Create Sub Process"}
        </Typography>
        <br></br>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <Box sx={{ flex: 1 }}>
            <Stack spacing={2}>
              <TextField
                defaultValue={interviewDate}
                value={interviewDate}
                onChange={(e) => setInterviewDate(e.target.value)}
                variant="outlined"
                type="date"
                label="Date"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableunderline: true }}
                error={!!interviewDateError}
                helperText={interviewDateError}
              />
              {/* <TextField
                select
                value={
                  ((!editMode) && processData && processData.consultant)
                    ? processData.consultant
                    : attendeeCode
                }
                onChange={(e) => setAttendeeCode(e.target.value)}
                disabled={processData && processData.consultant ? true : false}
                label="Assigned To"
                variant="outlined"
              >
                {profiles?.body?.content?.map((profile) => (
                  <MenuItem key={profile.id} value={profile.code}>
                    {profile.email}
                  </MenuItem>
                ))}
              </TextField> */}
              {(profiles && profiles?.body?.content.length >0) ? <Autocomplete
                  options={profiles?.body?.content?.map(t=> t.email)}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  value={processData.consultant}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setAttendeeCodeError('Code is required');
                    } else {
                      setAttendeeCodeError('');
                    }
                    setAttendeeCode(newValue);
                  }}
                    renderOption={(props, options) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assigned To"
                      variant="outlined"
                      error={!!attendeeCodeError}
                      helperText={attendeeCodeError}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                /> : 'Loading Profiles...'}
              <TextField
                value={interviewPanel}
                onChange={(e) => setInterviewPanel(e.target.value)}
                label="Interview Panel"
                variant="outlined"
                error={!!interviewPanelError}
                helperText={interviewPanelError}
              />
              <TextField
                value={meetingInvite}
                onChange={(e) => setMeetingInvite(e.target.value)}
                label="Meeting Invite"
                variant="outlined"
                error={!!meetingInviteError}
                helperText={meetingInviteError}
              />
              <TextField
                value={
                  processData && processData.clientEmail
                    ? processData.clientEmail
                    : clientEmail
                }
                onChange={(e) => setClientEmail(e.target.value)}
                label="Client Email"
                variant="outlined"
              />
              <TextField
                value={note}
                multiline
                label="Note"
                variant="outlined"
                onChange={(e) => setNote(e.target.value)}
                error={!!noteError}
                helperText={noteError}
                rows={5}
              />
            </Stack>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Stack spacing={2}>
              <TextField
                value={
                  processData && processData.clientCell
                    ? processData.clientCell
                    : clientCell
                }
                onChange={(e) => setClientCell(e.target.value)}
                label="Phone Number"
                variant="outlined"
              />
              {/* <TextField
                label="Status"
                variant="outlined"
                select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                error={!!statusError}
                helperText={statusError}
              >
                {config &&
                  config.store.STATUS.map((st) => (
                    <MenuItem key={st} value={st}>
                      {st}
                    </MenuItem>
                  ))}
              </TextField> */}
              {(config && config?.store?.STATUS) ?
                <Autocomplete
                  options={config && config.store.STATUS}
                  // inputValue={status}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  value={status}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setStatusError('Status is required');
                    } else {
                      setStatusError('');
                    }
                    setStatus(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={status}
                      label="Status"
                      variant="outlined"
                      error={!!statusError}
                      helperText={statusError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> : "Loading Stautus..."}

              {/* <TextField
                value={interviewMode}
                onChange={(e) => setInterviewMode(e.target.value)}
                label="Interview Mode"
                variant="outlined"
                error={!!interviewModeError}
                helperText={interviewModeError}
                select
              >
                <MenuItem value="Zoom">Zoom</MenuItem>
                <MenuItem value="Google_Meet">Google Meet</MenuItem>
                <MenuItem value="Teams">Teams</MenuItem>
                <MenuItem value="RingCentral">RingCentral</MenuItem>
                <MenuItem value="Cisco_Webex">Cisco Webex</MenuItem>
                <MenuItem value="Skype">Skype</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField> */}
              {(config && config?.store?.MODE_OF_COMM) ? 
                <Autocomplete
                  options={config && config?.store?.MODE_OF_COMM}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  value={interviewMode}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setInterviewModeError('Status is required');
                    } else {
                      setInterviewModeError('');
                    }
                    setInterviewMode(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={interviewMode}
                      label="Interview Mode"
                      variant="outlined"
                      error={!!interviewModeError}
                      helperText={interviewModeError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> : "Loading Mode of Communication List..."}

              <TextField
                value={time}
                onChange={(e) => setTime(e.target.value)}
                label="Time"
                variant="outlined"
                type="time"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableunderline: true }}
                error={!!timeError}
                helperText={timeError}
              />
             {(config && config?.store?.TIMEZONE) ?
              <Autocomplete
                  options={config?.store?.TIMEZONE}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  value={timeZone}
                  onChange={(e, newValue) => {
                    if (!newValue) {
                      setTimeZoneError('Time Zone is required');
                    } else {
                      setTimeZoneError('');
                    }
                    setTimeZone(newValue);
                  }}
                  renderOption={(props, options) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {options}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={timeZone}
                      label="Time Zone"
                      variant="outlined"
                      error={!!timeZoneError}
                      helperText={timeZoneError}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> : "Loading timezone..."}
            </Stack>
          </Box>
        </Box>
        {editMode ? (
          <Button
            variant="contained"
            onClick={handleEditSubmit}
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              marginTop: "20px",
            }}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              marginTop: "20px",
            }}
          >
            Create
          </Button>
        )}
      </Box>
    </>
  );
}
