import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Events"],

  endpoints: (builder) => ({
    getEventsByProcessId: builder.query({
      query: ({processId}) => `/api/v1/bpm/event/process/${processId}`,
    }),
    getEventsBySubProcessId: builder.query({
      query: ({subProcessId}) => `/api/v1/bpm/event/sub-process/${subProcessId}`,
    }),
    createEvent: builder.mutation({
      query: ({spId, newMessage}) => (
        {
        url: `/api/v1/bpm/event/sub-process/${spId}`,
        method: "POST",
        body: newMessage,
      }),
      invalidatesTags: ['Events']
    }),
    updateEvent: builder.mutation({
      query: ({id, modifyingMessage}) => ({
        url: `/api/v1/bpm/event/${id}`,
        method: "PATCH",
        body: modifyingMessage,
      }),
      invalidatesTags: ['Events']
    })
  }),
});

export const {
  useGetEventsByProcessIdQuery,
  useGetEventsBySubProcessIdQuery,
  useCreateEventMutation,
  useUpdateEventMutation
} = eventApi;
