import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from '../authenticate/SecureRequest';

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSearched: builder.query({
      query: (searchTerm) => `api/v1/bpm/search?searchTerm=${searchTerm}`,
    }),
    
  }),
});

export const {
  useGetSearchedQuery,
} = searchApi;
