import Moment from "moment";

export const isValidEmail = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
  );

export const isValidPhone = (phoneNumber) => {
    let phoneToValidate = phoneNumber;
    if(phoneNumber && phoneNumber.length > 10 && !phoneNumber.startsWith('+')){
        phoneToValidate = '+'+phoneNumber;
    }
    if(phoneToValidate.length===11 && phoneToValidate.startsWith('+')){
        return false;
    }
    if(phoneToValidate.length===10 && phoneToValidate.startsWith('0')){
        return false;
    }
    const nationalPattern = /^\d{10}$/;
    const internationalPattern = /^\+\d{1,3}\s?\d{1,14}$/;

    return nationalPattern.test(phoneToValidate) || internationalPattern.test(phoneToValidate) || false;
};

export const formateDate = (dateStr) => {
    return new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }).format(Moment(dateStr).toDate());
}

export const colorMap = {
    CREATED : '#e1c3f9',
    SCREENING : '#e7d909',
    TECHNICAL : '#d2f92e',
    MANAGERIAL : '#d2f92e',
    EMIGRATION : '#d2f92e',
    HR_DISCUSSION : '#d2f92e',
    RESPONSE_AWAITED : '#e3e4de',
    HOLD : '#e3ca03',
    PENDING : '#e3ca03',
    OFFER_PROCESSING : '#dbfabf',
    OFFER_RECEIVED : '#78f507',
    ONBOARDED : '#78f507',
    DOCUMENTATION : '#78f507',
    BACKGROUND_CHECK : '#78f507',
    DECLINED : '#f53607',
    REJECTED : '#f53607',
    MISSED : '#c9a2f5',
}

export const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');