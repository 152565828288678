import React from 'react'
import CardProcess from 'components/Cards/CardProcess.js';
import { useGetProcessQuery } from 'redux/features/services/process';

export default function ProcessData() {

    const { data: process } = useGetProcessQuery({ page: 0, size: 1000 });

  return (
    <>
      <div className="flex flex-wrap mt-0">
       <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-0">
          {console.log("Process---->>>>",process?.body?.content)}
          {(!process)? (
            <p>Loading...</p>
            ) : (
              <CardProcess processDataProps={process?.body?.content} />
              )}
        </div>
      </div>
    </>
  )
}
