import { useState } from 'react';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import './styles.css';

const fields = [
  { name: 'lastModifiedBy', label: 'Lead Generated By' },
  { name: 'lastModifiedDate', label: 'Created Date' },
  { name: 'interviewDate', label: 'Interview Date' },
  { name: 'process.candidate.code', label: 'Profile' },
  { name: 'consultant.code', label: 'Attendee' },
  { name: 'status', label: 'Status' },
  { name: 'process.source', label: 'Lead Source' },
  { name: 'process.client', label: 'Client' }
];

const combinators = [
  { name: 'AND', label: 'AND' },
  { name: 'OR', label: 'OR' },
];

const operators = [
  { name: 'EQUAL_TO', label: '=' },
  { name: 'NOT_EQUAL', label: '!=' },
  { name: 'LT', label: '<' },
  { name: 'GT', label: '>' },
  { name: 'LTE', label: '<=' },
  { name: 'GTE', label: '>=' },
  { name: 'LIKE', label: 'like' },
  // { name: 'beginsWith', label: 'begins with' },
  // { name: 'endsWith', label: 'ends with' },
  // { name: 'doesNotContain', label: 'does not contain' },
  // { name: 'doesNotBeginWith', label: 'does not begin with' },
  // { name: 'doesNotEndWith', label: 'does not end with' },
  { name: 'IS_NULL', label: 'is null' },
  { name: 'NOT_NULL', label: 'is not null' },
  { name: 'IN', label: 'in' },
  // { name: 'notIn', label: 'not in' },
  { name: 'between', label: 'between' },
  // { name: 'notBetween', label: 'not between' },
];

const initialQuery = {
  combinator: 'and',
  rules: [
    // { field: 'firstName', operator: 'beginsWith', value: 'Stev' },
    // { field: 'lastName', operator: 'in', value: 'Vai,Vaughan' },
  ],
};
export const MasterFilter = ({handleQuery, filterOptions}) => {
  const [query, setQuery] = useState(initialQuery);
  const [color, setColor] = useState('light');
  const replace = (str, src, target) => (src, target) => (src && target) ? replace(str.replace(new RegExp("\\b"+src+"\\b","gi"),target),src,target) : str;

  const applyFilter = () => {
    let q = JSON.stringify(formatQuery(query, 'json'));
    const payload = replace(q,"and","AND")("and","AND")("or","OR")("between","BETWEEN")();
    handleQuery(JSON.parse(payload));
  }

  return (
    <>
    <div 
    style={{
      margin: "5% auto",
      background: "#fff",
      position: "relative",
      padding: 10,
      width: "95%",
      borderRadius: "10px"
    }}
    >
      <QueryBuilder
        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
        fields={filterOptions || fields}
        operators={operators}
        combinators={combinators}
        query={query}
        onQueryChange={q => setQuery(q)}
      />
      <button class="button" style={{verticalAlign:"middle"}} onClick={applyFilter}><span>Apply Filter </span></button>
    </div>
    </>
  );
};
