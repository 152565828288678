import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Moment from "moment";
import AddSubProcessForm from "views/admin/AddSubProcessForm";
import Modal from "@mui/material/Modal"; // Step 1: Import the Modal component
import { useGetSubProcessQuery } from "redux/features/services/subProcess";
import "./Cardsubprocess.css";
import Zoom from "../../assets/img/zoom.svg";
import CiscoWebex from "../../assets/img/CiscoWebex.svg";
import GoogleMeet from "../../assets/img/GoogleMeet.svg";
import Teams from "../../assets/img/Teams.svg";
import Skype from "../../assets/img/Skype.svg";
import RingCentral from "../../assets/img/RingCentral.svg";
import { IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import Event from "views/admin/Event";
import ProfileFinder from "components/utils/ProfileFinder";
import CardTimeline from "./CardTimeline";

export default function CardSubProcess({
  processId,
  processData,
  onSubProcessUpsert
}) {
  const [editMode, setEditMode] = useState(false);

  const { data: subProcessList, refetch: refetchSubProcess } =
    useGetSubProcessQuery({
      processId: processId,
    });

  const [openSubProcessModaleForm, setopenSubProcessModaleForm] =
    useState(false);

  const handleSubProcessModaleOpen = () => {
    setopenSubProcessModaleForm(true);
  };

  const handleSubProcessModaleClose = () => {
    setopenSubProcessModaleForm(false);
    setEditMode(false);
  };

  const [editingSubProcess, setEditingSubProcess] = useState(null);

  const handleEditSubProcessModaleOpen = (subProcess) => {
    setEditingSubProcess(subProcess);
    setopenSubProcessModaleForm(true);
    setEditMode(true);
  };

  const handleSubProcessFormClose = () => {
    handleSubProcessModaleClose();
    onSubProcessUpsert();
    console.log("Setting edit mode false");
    setEditMode(false);
  };

  const [openEventModalForm, setopenEventModalForm] = useState(false);

  const handleEventModalOpen = () => {
    setopenEventModalForm(true);
  };

  const handleEventModaleClose = () => {
    setopenEventModalForm(false);
  };

  const getInterviewMode = (mode) => {
    let currentMode = mode;
    switch (mode) {
      case "Zoom": {
        currentMode = <img src={Zoom} alt="Zoom" />;
        break;
      }
      case "Google_Meet": {
        currentMode = <img src={GoogleMeet} alt="Google Meet" />;
        break;
      }
      case "Teams": {
        currentMode = <img src={Teams} alt="Microsoft Teams" />;
        break;
      }
      case "RingCentral": {
        currentMode = <img src={RingCentral} alt="RingCentral" />;
        break;
      }
      case "Cisco_Webex": {
        currentMode = <img src={CiscoWebex} alt="Cisco Webex" />;
        break;
      }
      case "Skype": {
        currentMode = <img src={Skype} alt="Skype" />;
        break;
      }
      default: {
        break;
      }
    }
    return currentMode;
  };

  const handleAddSubProcess = (newSubProcess) => {
    refetchSubProcess(); // Call the refetch function to update the subProcessList
    handleSubProcessModaleClose();
  };
  
  return (
    <>
      <div
        className="subprocess-table"
        style={{
          margin: "5% auto",
          background: "#fff",
          position: "relative",
          padding: 10,
          width: "95%",
        }}
      >
        <Button 
        variant="contained"
        style={{ float: "right", marginBottom: "10px" }}
        onClick={handleEventModalOpen}
        >
              Show Event
        </Button>
        <Modal
          open={openEventModalForm}
          onClose={handleEventModaleClose}
        >
          <CardTimeline processId={processId} subProcessId={subProcessList}/>
        </Modal>
        <Button
          variant="contained"
          style={{ float: "right", marginBottom: "10px" }}
          onClick={handleSubProcessModaleOpen}
        >
          Add Sub Process
        </Button>
        <table className="items-center block w-full overflow-x-auto">
          <thead className="thead_space_dv">
            <tr>
              <th
                style={{ display: "none" }}
                className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                ID
              </th>
              <th
                className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                style={{ width: "164px", whiteSpace: "nowrap" }}
              >
                Interview Date
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Assigned To
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Interview Panel
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Client Email
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Phone Number
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Status
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Note
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Time
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                TimeZone
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Interview Mode
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Meeting Invite
              </th>
              <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {subProcessList?.map((subProcess, index) => (
              <tr key={index}>
                {console.log(processId)}
                <th
                  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  style={{ display: "none" }}
                >
                  {subProcess.id}
                </th>

                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  }).format(Moment(subProcess.interviewDate).toDate())}
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <ProfileFinder id={subProcess.consultant} resolver={(data)=>data['firstName']+" "+data['lastName']} />
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.interviewPanel}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.clientEmail}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.clientCell}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.status}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.note}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.time}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.timeZone}
                </td>
                {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.interviewMode}
                </td> */}
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.interviewMode
                    ? getInterviewMode(subProcess.interviewMode)
                    : null}
                </td>

                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {subProcess.meetingInvite ? (
                    <IconButton
                      aria-label={subProcess.interviewMode}
                      onClick={() =>
                        window.open(subProcess.meetingInvite, "_blank")
                      }
                    >
                      <LaunchIcon />
                    </IconButton>
                  ) : null}
                </td>

                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <EditIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditSubProcessModaleOpen(subProcess)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          open={openSubProcessModaleForm}
          onClose={handleSubProcessModaleClose}
        >
          <AddSubProcessForm
            process={processId}
            processData={processData}
            onClose={handleSubProcessFormClose}
            onAddSubProcess={handleAddSubProcess}
            onUpdateSubProcess={handleAddSubProcess}
            editingSubProcess={editMode ? editingSubProcess : {}}
            editMode={editMode}
          />
        </Modal>
      </div>
    </>
  );
}
