import { createSlice } from "@reduxjs/toolkit";

// initialize userToken from local storage
const loadAuthState = () =>{
  return {
    user: localStorage.getItem('user') ? localStorage.getItem('user') : null,
    accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null,
    refreshToken: localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null,
    authorities: localStorage.getItem('authorities') ? JSON.parse(localStorage.getItem('authorities')) : null
  }
}

const initialState = loadAuthState();

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      console.log("Auth Slice: ",state.user)
      localStorage.setItem("user",action.payload);
    },
    setAuthorities: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem("authorities",JSON.stringify(action.payload));
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      console.log("Setting accessToken....",action.payload)
      localStorage.setItem("accessToken",action.payload)
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      console.log("Setting refreshToken....",action.payload)
      localStorage.setItem("refreshToken",action.payload)
    },
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.authorities = null;
      state.isAuthenticated = false;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authorities")
    },
  },
});

export const { setUser, setAccessToken, setRefreshToken, logout, setAuthorities } = authSlice.actions;

export default authSlice.reducer;
