import React, { useState } from "react";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import "./CardProfiles.css";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete"
import { saveAs } from "file-saver";
import ContactForm from "views/admin/AddContactForm";
import { useDeleteContactMutation, useGetContactsQuery } from "redux/features/services/contact.service";

export default function CardContact({ contactData : initialContactData }) {
  const [openContactModalForm, setOpenContactModalForm] = useState(false);
  const [contactData, setContactData] = useState(initialContactData);
  const [editableRow, setEditableRow] = useState(null);
//   const [editedContactData, setEditedContactData] = useState({});
  const [selected, setSelected] = useState([]);

  const { refetch } = useGetContactsQuery()
  const [deleteContact, response] = useDeleteContactMutation()

  const handleContactModalOpen = () => {
    setOpenContactModalForm(true);
  };

  const handleContactModalClose = () => {
    setOpenContactModalForm(false);
  };

  const handleContactFormClose = () => {
    handleContactModalClose();
  };

  const handleContactAdd = (newContact) => {
    const updatedContactData = [...contactData, newContact];
    setContactData(updatedContactData);
  };

const handleDownload = () => {
    function convertToCSV(data) {
      const header = columns.map((column) => column.field);
      const rows = data.map((item) => header.map((field) => item[field]));
      const csvContent = [
        header.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "table_data.csv");
    }
    convertToCSV(initialContactData);
  };

  const handleEditClick = (event,index) => {
    event.stopPropagation();
    // setEditableRow(index);
    // setEditedContactData({ ...contactData[index] });
  };

  const handleSaveEdit = (event,index) => {
    event.stopPropagation();
    // const updatedContactData = [...contactData];
    // updatedContactData[index] = editedContactData;
    // setContactData(updatedContactData);
    // setEditableRow(null);
  };

  const handleSelect = (selection) => {
    setSelected(selection.selectionModel);
  };

    const handleDeleteClick=(event, id)=>{
        event.stopPropagation();
        // console.log("id of the iten to be deleted",id)
        deleteContact(id).then(()=>{
        refetch()
      })
    }

    if(response.isSuccess){
      console.log(response,"deleted contact")
    }

  const columns = [
    
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      editable: true,
      headerClassName: "custom-header",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: (params) => {
        return(
            <div style={{ display: "flex", gap: "9px" }}>
            {editableRow === params.rowIndex ? (
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => handleSaveEdit(event,params.rowIndex)}
              >
                Save
              </Button>
            ) : (
              <EditIcon
                style={{ cursor: "pointer" }}
                onClick={(event) => handleEditClick(event,params.rowIndex)}
              />
            )}
            <DeleteIcon
              onClick={(event) => handleDeleteClick(event,params.id)}
              style={{ cursor: "pointer" }}
            />
          </div>
        )
      },
    },
  ];

  const rows = contactData.map((contact) => ({
    id: contact.id,
    ...contact,
  }));

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                All Contacts
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={() => window.print()}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
              <Button
                style={{ margin: "3px" }}
                variant="contained"
                onClick={handleDownload}
                startIcon={<DownloadIcon />}
              >
                Download
              </Button>
              <Button
                className="bg-indigo-500 text-white active-bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleContactModalOpen}
                variant="contained"
              >
                Add Contact
              </Button>
            </div>
          </div>
        </div>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter={true}
            checkboxSelection
            onSelectionModelChange={handleSelect}
            // pagination
            onEditCellChangeCommitted={(params) => {
              // Handle cell edit commit here
              const updatedContactData = [...contactData];
              updatedContactData[params.rowIndex][params.field] =
                params.props.value;
                setContactData(updatedContactData);
            }}
          />
        </div>
      </div>
      <Modal open={openContactModalForm} onClose={handleContactModalClose}>
        <ContactForm
          onClose={handleContactFormClose}
          onContactAdd={handleContactAdd}
        />
      </Modal>
    </>
  );
}
