import React, { useState } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import { useGetEventsByProcessIdQuery } from 'redux/features/services/event.service';
import Chip from '@mui/material/Chip';
import { formateDate } from 'components/utils/Constant';
import { CalendarMonth, Cancel, Edit, Info, InfoOutlined, People, PersonPin, Save } from '@mui/icons-material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import TextField from "@mui/material/TextField";
import { useCreateEventMutation } from 'redux/features/services/event.service';
import { useUpdateEventMutation } from 'redux/features/services/event.service';
import { colorMap } from "components/utils/Constant";


export default function CardTimeline({processId, subProcessId}) {

  const { data: events, refetch: refetchEvents, isLoading: isEventsLoading } = useGetEventsByProcessIdQuery({processId: processId});

  const [newMessage, setNewMessage] = useState("");
  const [newMessageError, setNewMessageError] = useState("");
  const [modifyingMessage, setModifyingMessage] = useState("");
  const [modifyingMessageError, setModifyingMessageError] = useState("");
  const [createEvent, { isLoading: isEventCreating }] = useCreateEventMutation();
  const [updateEvent, { isLoading: isEventUpdating }] = useUpdateEventMutation();
  const [editingEventId, setEditingEventId] = useState("");
  const [editMode, setEditMode] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: "45%",
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const saveNewEvent = async() => {
    console.log('Saving new process id: ',subProcessId);
    const spId = events[0]?.subProcessId || subProcessId[subProcessId?.length-1]?.id;
    console.log("saving event for id: ",spId, " Message: ",newMessage);
    try{
      const resp = await createEvent({spId, newMessage}).unwrap();
      setNewMessageError("");
      refetchEvents();
      setNewMessage('');
      console.log('Event created: ',resp);
    }catch(err) {
      console.log('Error : ', err);
      setNewMessageError(err?.data?.message);
      console.log('Error creating new event: ',newMessageError);
    }
  }

  const modifyEvent = async(id) => {
    try{
      const resp = await updateEvent({id, modifyingMessage}).unwrap();
      setModifyingMessageError("");
      refetchEvents();
      setModifyingMessage('');
      setEditingEventId('');
      console.log('Event Modified: ',resp);
    }catch(err) {
      console.log('Error : ', err);
      setModifyingMessageError(err?.data?.message);
      console.log('Error modifying event: ',newMessageError);
    }
  }

  const cancelEditingEvent = () =>{
    setModifyingMessage('');
    setModifyingMessageError('');
    setEditMode(false);
    setEditingEventId('');
  }

  return (
    <>
      {console.log('Events for process: ',processId, "- ",events)}
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          zIndex: 99,
        }}
      >
        {editMode ? '' :
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px"
            }}
          >
            <TextField
              multiline
              rows={2}
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              label="Message"
              variant="outlined"
              error={!!newMessageError}
              helperText={newMessageError}
              style={{
                width: "30%"
              }}
            />
            <Button 
              disabled={newMessage.trim() == "" && newMessageError.trim() == ""} 
              onClick={saveNewEvent}
              variant="contained"
            >
              {isEventCreating ? "Adding..." : "Add Event"}
            </Button>
          </div>
        }
        <Timeline position="alternate">
          {isEventsLoading ? 'Loading...' : 
            events?.map(e=>(
              <TimelineItem>
                <TimelineOppositeContent
                  sx={{ m: 'auto 0' }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  <CalendarMonth />{formateDate(e.creationDate)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  {/* <TimelineDot> */}
                  <Chip label={e.status} style={{background: colorMap[e.status]}} />
                  {/* </TimelineDot> */}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <PersonPin />
                  <Typography variant="h6" component="span">
                    {e.createdBy.split('@')[0].toUpperCase()}
                  </Typography>
                  <Typography>{e.message}
                    {editMode && editingEventId===e.id ?
                      <div>
                        <Save onClick={()=>modifyEvent(e.id)}/>
                        <Cancel onClick={()=>cancelEditingEvent()}/>
                        <div
                          // style={{
                          //   display: "flex",
                          //   gap: "10px",
                          //   alignItems: "center",
                          //   justifyContent: "center",
                          //   marginBottom: "10px"
                          // }}
                        >
                          <TextField
                            multiline
                            rows={2}
                            type="text"
                            value={modifyingMessage}
                            onChange={(e) => setModifyingMessage(e.target.value)}
                            label="Message"
                            variant="outlined"
                            error={!!modifyingMessageError}
                            helperText={modifyingMessageError}
                            style={{
                              width: "30%"
                            }}
                          />
                        </div>
                      </div>
                      : <Edit onClick={()=>{setEditingEventId(e.id);setEditMode(true);setModifyingMessage(e.message)}}/> 
                    }
                    <HtmlTooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title={
                        <React.Fragment>
                          <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                              <h3 className="font-semibold text-base text-blueGray-700">
                                {e.client?.toUpperCase()} - {e.profileEmail?.split('@')[0]?.toUpperCase() || ''}
                              </h3>
                            </div>
                          </div>
                          <div className="block w-full overflow-x-auto ">
                            <ul className="unorder_list">
                                <li className="list"><span className="list_key">Client : </span>{e.client}</li>
                                <li className="list"><span className="list_key">Client Email : </span>{e.clientEmail}</li>
                                <li className="list"><span className="list_key">Consultant : </span>{e.consultantEmail}</li>
                                <li className="list"><span className="list_key">Profile Code : </span>{e.profileEmail}</li>
                                <li className="list"><span className="list_key">Status : </span>{e.status}</li>
                                <li className="list"><span className="list_key">Created on : </span>{formateDate(e.creationDate)}</li>
                                <li className="list"><span className="list_key">Created By : </span>{e.createdBy}</li>
                                <li className="list"><span className="list_key">Updated By : </span>{e.lastModifiedBy}</li>
                            </ul>
                          </div>
                        </React.Fragment>
                      }>
                      <InfoOutlined />
                    </HtmlTooltip>
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))
          }
          {/* <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              9:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <FastfoodIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                Eat
              </Typography>
              <Typography>Because you need strength</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary">
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                Code
              </Typography>
              <Typography>Because it&apos;s awesome!</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary" variant="outlined">
                <HotelIcon />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                Sleep
              </Typography>
              <Typography>Because you need rest</Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
              <TimelineDot color="secondary">
                <RepeatIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                Repeat
              </Typography>
              <Typography>Because this is the life you love!</Typography>
            </TimelineContent>
          </TimelineItem> */}
        </Timeline>
      </Box>
    </>
  );
}